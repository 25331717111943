import * as React from 'react';
import classnames from 'classnames';
import $ from './icon.scss';

type Size = 'small' | 'mini';

type Props = {
    className?: string;
    size?: Size;
};

// default: 24px
// small: 20px
// mini: 10px
const getSize = (size?: Size) => {
    switch (size) {
        case 'mini':
            return $.icon10;
        case 'small':
            return $.icon20;
        default:
            return $.icon24;
    }
};

const Icon: React.FunctionComponent<Props> = (props: Props) => {
    const { className, size } = props;
    if (!className) return null;

    return <span className={classnames($.icon, className, getSize(size))} />;
};

export default Icon;
