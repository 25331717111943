import * as React from 'react';
import { CardHorizontal as CardHorizontalType } from '~source/core/models';
import { Title, Button, Link, Image } from '~source/ui/components';
import $ from './card-horizontal.scss';

const CardHorizontal: React.FunctionComponent<CardHorizontalType> = props => {
    const { title, subTitle, link, image } = props;

    return (
        <div className={$.cardHorizontal}>
            {image && (
                <Image
                    placeholder={`${image.url}?fit=fill&h=30&w=30&q=50`}
                    src={`${image.url}?fit=fill&h=300&w=300&q=75`}
                    alt={image.title}
                    className={$.image}
                />
            )}
            <section className={$.content}>
                {title && <Title title={title} className={$.title} />}
                {subTitle && <p className={$.body}>{subTitle}</p>}
            </section>
            {link && link.url && (
                <>
                    {/* hidden link to make entire block clickable */}
                    <Link className={$.fullCardLink} to={link.url}>
                        {' '}
                    </Link>
                    <section className={$.button}>
                        <Button
                            color="blue"
                            to={link.url}
                            openInNewTab={link.openInNewTab}
                            icon="chevron-right-white"
                        >
                            <span className={$.buttonTitle}>{link.title}</span>
                        </Button>
                    </section>
                </>
            )}
        </div>
    );
};

export default CardHorizontal;
