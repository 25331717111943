import React from 'react';
import { Services as ServicesModel } from '~source/core/models';
import { ServicesDefault, ServicesList } from '~source/ui/components/modules';

const Services: React.FunctionComponent<ServicesModel> = props => {
    switch (props.layout) {
        case 'list':
            return <ServicesList {...props} />;
        default:
            return <ServicesDefault {...props} />;
    }
};

export default Services;
