import React from 'react';
/**
 * Hook that executes a function when a user clicks outside an element or elements
 */
export default function useOutsideClick(
    ref:
        | React.RefObject<HTMLElement | null | undefined>
        | React.RefObject<HTMLElement | null | undefined>[],
    handler: () => void,
) {
    // Turn ref in to an array of elements, make things easier for check in `handleClickOutside`
    const elements = Array.isArray(ref) ? ref : [ref];
    React.useEffect(() => {
        const isOutside = (
            el: React.RefObject<HTMLElement | null | undefined>,
            target: HTMLElement,
        ) =>
            el &&
            el.current &&
            el.current !== target &&
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            !el.current.contains(target as any);
        /**
         * Execute handler when clicked outside the ref
         */
        const handleClickOutside = (event: MouseEvent) => {
            // Check if the event.target is an outside click relative to each element
            // Fire handler if so.
            if (
                elements &&
                elements.every(
                    el => !!isOutside(el, event.target as HTMLElement),
                )
            ) {
                handler();
            }
        };

        // Bind event listener on mount
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Unbind evend listener on unmount
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handler, elements]);
}
