import * as React from 'react';
import classnames from 'classnames';
import InitContext from '~source/ui/context/init-context';
import useLabel from '~source/ui/hooks/use-label';
import {
    CopyrightNotice,
    FooterCard,
    MenuItems,
    SocialMediaButtons,
    Container,
} from '~source/ui/components';
import $ from './footer.scss';
import Analytics, { tracking } from '~source/core/services/analytics';

const Footer: React.FunctionComponent = () => {
    const init = React.useContext(InitContext);
    const goQuicklyTo = useLabel('GoQuicklyTo');
    const clickHandler = React.useCallback(i => {
        Analytics.event(tracking.events.footerMenu, 'events', {
            category: tracking.categories.footer,
            action: i.title,
            label: '',
        });
    }, []);
    if (!init) return null;
    const { footerPrimaryMenu, footerSecondaryMenu } = init;

    return (
        <footer>
            <div className={$.row}>
                <Container>
                    <div className={classnames('row-of-2', $.content)}>
                        <div>
                            <div className={$.menusTitle}>{goQuicklyTo}</div>
                            <div className={classnames('row-of-2', $.menus)}>
                                <MenuItems
                                    items={footerPrimaryMenu}
                                    onClick={clickHandler}
                                    vertical
                                />
                                <MenuItems
                                    className={$.menuSecondary}
                                    items={footerSecondaryMenu}
                                    onClick={clickHandler}
                                    vertical
                                />
                            </div>
                        </div>

                        <FooterCard />
                    </div>
                </Container>
            </div>

            <div className={classnames($.row, $.rowDark)}>
                <Container>
                    <div
                        className={classnames(
                            'row-of-2',
                            $.footerCopyrightAndSocial,
                        )}
                    >
                        <CopyrightNotice />
                        <div className={$.social}>
                            <SocialMediaButtons location="footer" />
                        </div>
                    </div>
                </Container>
            </div>
        </footer>
    );
};

export default Footer;
