import { ModuleCard, Link, StyledFont } from '../models';
import { isString, isImage, isBool } from '~source/utils/check-type';
import { transformLink } from '~source/core/transforms';

type Image = {
    id: string;
    title: string;
    url: string;
};

type Entity = {
    backgroundColor: string;
    backgroundImage: Image;
    body: string;
    body2: string;
    buttonText: string;
    foregroundImage: Image;
    foregroundImageAlignment: string;
    id: string;
    image: Image;
    imageAlignment: string;
    layout?: string;
    lightText: boolean;
    link: Link;
    showBackButton: boolean;
    title: string;
    titleFont: StyledFont;
    topTitle: string;
    type: string;
    showGradient?: boolean;
};

export default function transformCard(data: Entity): ModuleCard {
    if (!data) throw new Error('Card: no data');

    const id = data.id && isString(data.id);
    const title = data.title && isString(data.title);
    const subtitle = data.topTitle && isString(data.topTitle);
    const text = data.body && isString(data.body);
    const text2 = data.body2 && isString(data.body2);
    const lightText = data.lightText && isBool(data.lightText);
    const color = lightText ? '#ffffff' : undefined;
    const layout = data.layout ? data.layout : 'default';
    const image =
        (data.foregroundImage && isImage(data.foregroundImage)) ||
        (data.image && isImage(data.image));
    const imageAlignment =
        (data.foregroundImageAlignment &&
            isString(data.foregroundImageAlignment)) ||
        (data.imageAlignment && isString(data.imageAlignment));
    const backgroundImage =
        data.backgroundImage && isImage(data.backgroundImage);
    const backgroundColor =
        data.backgroundColor && isString(data.backgroundColor);
    const ctaButton = data.link && transformLink(data.link);
    const showBackButton = data.showBackButton;
    const titleFont = data.titleFont;
    const showGradient = data.showGradient ? true : false;
    const type = 'card';

    return {
        backgroundColor,
        backgroundImage,
        color,
        ctaButton,
        id,
        image,
        imageAlignment,
        layout,
        showBackButton,
        showGradient,
        subtitle,
        text,
        text2,
        title,
        titleFont,
        type,
    };
}
