import React from 'react';
import { InitContext } from '~source/ui/context';
import {
    Button,
    Checkbox,
    HtmlText,
    Input,
    Title,
} from '~source/ui/components';
import { Image } from '~source/core/models';
import { isRequired } from '~source/utils/form-validation';
import { useFormValue, useLabel } from '~source/ui/hooks';
import $ from './login-form.scss';

export interface FormData {
    user: string;
    password: string;
    rememberMe: boolean;
}

export interface LoginFormProps {
    backgroundImage?: Image;
    body: string;
    error: boolean;
    onSubmit: (data: FormData) => void;
    subtitle: string;
    success: boolean;
    title?: string;
}

const LoginForm: React.FC<LoginFormProps> = ({
    backgroundImage = null,
    body,
    error,
    onSubmit,
    subtitle,
    success,
    title,
}) => {
    const init = React.useContext(InitContext);
    const email = useFormValue('', isRequired);
    const password = useFormValue('', isRequired);
    const [rememberMe, setRememberMe] = React.useState(false);

    const labels = {
        button: useLabel('LoginForm/Button'),
        email: useLabel('LoginForm/Email'),
        emailInvalid: useLabel('LoginForm/UsernameInvalid'),
        error: useLabel('LoginForm/WrongPasswordUsername'),
        forgotPassword: useLabel('LoginForm/ForgotPassword'),
        password: useLabel('LoginForm/Password'),
        passwordInvalid: useLabel('LoginForm/PasswordInvalid'),
        rememberMe: useLabel('LoginForm/RememberMe'),
    };

    const submitHandler = React.useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            const formData = {
                user: email.value,
                password: password.value,
                rememberMe,
            };

            onSubmit(formData);
        },
        [email.value, onSubmit, password.value, rememberMe],
    );

    React.useEffect(() => {
        if (success && init && init.shopUrl) {
            window.location.href = init.shopUrl;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [success]);

    if (!init) return null;

    return (
        <div className={$.background}>
            {backgroundImage &&
                backgroundImage.url &&
                backgroundImage.title && (
                    <img
                        src={backgroundImage.url}
                        alt={backgroundImage.title}
                        className={$.image}
                    />
                )}
            <div className={$.element}>
                <Title
                    title={title}
                    subtitle={subtitle}
                    className={$.head}
                    size="large"
                />
                <HtmlText className={$.headBody} content={body} />
                <form className={$.form} onSubmit={submitHandler}>
                    <Input
                        errorMessage={labels.emailInvalid}
                        onChange={email.onChange}
                        placeholder={labels.email}
                        valid={email.valid}
                        value={email.value}
                    />
                    <Input
                        errorMessage={labels.passwordInvalid}
                        onChange={password.onChange}
                        placeholder={labels.password}
                        type="password"
                        valid={password.valid}
                        value={password.value}
                        viewable
                    />
                    {error && <div className={$.error}>{labels.error}</div>}
                    <div className={$.checkboxButton}>
                        <Checkbox
                            checked={rememberMe}
                            className={$.checkbox}
                            onChange={() => setRememberMe(!rememberMe)}
                        >
                            {labels.rememberMe}
                        </Checkbox>
                        <Button className={$.button} type="submit">
                            {labels.button}
                        </Button>
                    </div>
                </form>
                <a
                    className={$.link}
                    href="https://web.fleurametz.com/forgot-password"
                >
                    {labels.forgotPassword}
                </a>
            </div>
        </div>
    );
};

export default LoginForm;
