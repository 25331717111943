import { TextImage } from '../models';
import { isBool } from '~source/utils/check-type';
import transformCard from './transformCard';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Entity = any;

export default function transformTextImage(data: Entity): TextImage {
    if (!data) throw new Error('TextImage module: no data');

    const type = 'text-image';
    const {
        id,
        title,
        subtitle,
        text,
        text2,
        color,
        image,
        imageAlignment,
        backgroundColor,
        ctaButton,
    } = transformCard(data);
    const orientationReversed = data.flip && isBool(data.flip);

    return {
        type,
        id,
        title,
        subtitle,
        text,
        text2,
        image,
        imageAlignment,
        backgroundColor,
        color,
        orientationReversed,
        ctaButton,
    };
}
