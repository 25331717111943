import * as React from 'react';
import fetchPage from '../../core/services/fetch-page';
import ConfigContext from '../context/config-context';

type FetchPageType = UnpackPromise<ReturnType<typeof fetchPage>>;

export default function usePageService() {
    const config = React.useContext(ConfigContext);
    const [pending, setPending] = React.useState<boolean>(true);
    const [data, setData] = React.useState<FetchPageType | null>(null);

    const fetchData = React.useMemo(
        () => async () => {
            setPending(true);
            const response = await fetchPage(config);
            if (response) {
                setData(response);
            }
            setPending(false);
        },
        [config],
    );

    return { pending, data, fetchData };
}
