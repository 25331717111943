import * as React from 'react';
import Button from '~source/ui/components/button';
import $ from './success-message.scss';

type Props = {
    message: string;
    backLabel: string;
    resetForm: () => void;
};

const SuccesMessage: React.FunctionComponent<Props> = props => {
    const { message, backLabel, resetForm } = props;
    return (
        <section className={$.success}>
            <img
                className={$.image}
                src="/static/icons/check-large.svg"
                alt=""
            />
            <p className={$.text}>{message}</p>
            <Button onClick={resetForm} className={$.button}>
                {backLabel}
            </Button>
        </section>
    );
};

export default SuccesMessage;
