import { Header } from '~source/core/models';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function transformHeader(data: any): Header {
    return {
        hasLightTheme: !!data.hasLightTheme,
        image: data.image,
        linkLeft: data.linkLeft,
        linkRight: data.linkRight,
        subtitle: data.subtitle,
        tag: data.tag,
        title: data.title,
    };
}
