import * as React from 'react';
import classnames from 'classnames';
import Image from '../image';
import { Image as ImageType } from '~source/core/models';
import breakpoints from '~source/core/constants/breakpoints';
import $ from './header-image.scss';

type Props = {
    image: ImageType;
    isLargeHeaderImage?: boolean;
};

const HeaderImage: React.FunctionComponent<Props> = props => {
    const { image, isLargeHeaderImage } = props;
    if (!image || !image.url) return null;
    const imageWidth = window.innerWidth <= breakpoints.medium ? 550 : 1600;

    return (
        <section
            className={classnames(
                $.headerImage,
                isLargeHeaderImage && $.headerImageLarge,
            )}
        >
            <Image
                placeholder={`${image.url}?w=30&q=50`}
                src={`${image.url}?w=${imageWidth}&q=75`}
                alt={image.title}
                isBackground
            />
        </section>
    );
};

export default HeaderImage;
