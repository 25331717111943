import React from 'react';
import cx from 'classnames';
import $ from './grid.scss';

export type GridSize = 2 | 3 | 4 | 5 | 6;
export type CellSize = 'small' | 'medium' | 'large' | 'large-wide';

interface Props {
    size: GridSize;
    renderCell: (index: number, cellSize: CellSize) => React.ReactNode;
}

function createCells(size: number) {
    const cells: { id: number }[] = [];
    for (let i = 0; size > i; i += 1) {
        cells.push({ id: i });
    }
    return cells;
}

const gridSizeToCellSize = {
    2: {
        0: 'medium',
        1: 'medium',
    },
    3: {
        0: 'large',
        1: 'small',
        2: 'small',
    },
    4: {
        0: 'large-wide',
        1: 'small',
        2: 'small',
        3: 'small',
    },
    5: {
        0: 'medium',
        1: 'medium',
        2: 'small',
        3: 'small',
        4: 'small',
    },
    6: {
        0: 'large',
        1: 'small',
        2: 'small',
        3: 'small',
        4: 'small',
        5: 'small',
    },
};

const Grid: React.FC<Props> = ({ renderCell, size }) => {
    if (size < 2 || size > 6) {
        console.warn(
            `Expected grid size to be between 2 and 6. Received: ${size}`,
        );
        return null;
    }
    const cells = createCells(size);
    return (
        <div
            className={cx($.grid, {
                [$.gridTwo as string]: size === 2,
                [$.gridThree as string]: size === 3,
                [$.gridFour as string]: size === 4,
                [$.gridFive as string]: size === 5,
                [$.gridSix as string]: size === 6,
            })}
        >
            {cells.map((cell, index) => (
                <div key={cell.id} className={$.gridCell}>
                    {renderCell(index, gridSizeToCellSize[size][index])}
                </div>
            ))}
        </div>
    );
};

export default Grid;
