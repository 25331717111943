import React from 'react';
import classnames from 'classnames';
import { ModuleCard } from '~source/core/models';
import Image from '~source/ui/components/image';
import Card from '~source/ui/components/card';
import Link from '~source/ui/components/link';
import useImageSize from '~source/ui/hooks/use-image-size';
import $ from './featured-item.scss';

type Props = {
    index: number;
    item: ModuleCard;
    largeOnTop: boolean;
};

const FeaturedItem: React.FunctionComponent<Props> = ({
    index,
    item,
    largeOnTop,
}) => {
    const {
        backgroundColor,
        backgroundImage,
        color,
        ctaButton,
        image,
        imageAlignment,
        showGradient,
    } = item;

    const { medium: imageSizeMedium, large: imageSizeLarge } = useImageSize();
    const indexSize = index === 0 ? 'medium' : 'small';
    const size = largeOnTop ? 'large' : indexSize;
    const imageWidth = 420;
    const backgroundImageWidth =
        size === 'large' ? imageSizeLarge : imageSizeMedium;
    const hasImageOverflow = imageAlignment === 'bottomRightOverlap';

    const imageStyle = classnames([
        $.image,
        (imageAlignment === 'top' ||
            imageAlignment === 'topRight' ||
            imageAlignment === 'topLeft') &&
            $.imageTop,
        (imageAlignment === 'bottom' ||
            imageAlignment === 'bottomRight' ||
            imageAlignment === 'bottomLeft' ||
            hasImageOverflow) &&
            $.imageBottom,
        (imageAlignment === 'right' ||
            imageAlignment === 'topRight' ||
            imageAlignment === 'bottomRight' ||
            hasImageOverflow) &&
            $.imageRight,
        (imageAlignment === 'left' ||
            imageAlignment === 'topLeft' ||
            imageAlignment === 'bottomLeft') &&
            $.imageLeft,
        imageAlignment === 'center' && $.imageCenter,
        hasImageOverflow && $.imageRightOverlap,
    ]);

    return (
        <article
            style={{
                backgroundColor,
                color,
            }}
            className={classnames(
                $.featured,
                index === 0 && $.featuredLarge,
                hasImageOverflow && $.featuredOverflow,
            )}
        >
            {backgroundImage && backgroundImage.url && (
                <Image
                    alt={backgroundImage.title}
                    className={$.backgroundImage}
                    isBackground
                    placeholder={`${backgroundImage.url}?w=30&q=50`}
                    src={`${
                        backgroundImage.url
                    }?w=${backgroundImageWidth}&q=75`}
                />
            )}

            {backgroundImage && showGradient && <div className="shadow" />}

            <div className={$.container}>
                <Card size={size} {...item} />
            </div>

            {image && (
                <Image
                    alt={image.title}
                    className={imageStyle}
                    placeholder={`${image.url}?w=30&q=50`}
                    src={`${image.url}?w=${imageWidth}&q=75`}
                />
            )}
            {ctaButton && (
                <a
                    className={$.linkOverlay}
                    href={ctaButton.url}
                    target={ctaButton.openInNewTab ? '_blank' : '_self'}
                />
            )}
        </article>
    );
};

export default FeaturedItem;
