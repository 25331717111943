export const pageLabels = {
    full: 'full',
    withSidebar: 'withSidebar',
    article: 'article',
    category: 'category',
};

const pageTypes = {
    [pageLabels.full]: 'a11',
    [pageLabels.withSidebar]: 'a14',
    [pageLabels.article]: 'a16',
    [pageLabels.category]: 'a31',
};

export default pageTypes;
