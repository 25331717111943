import * as React from 'react';

import { Image } from '~source/core/models';

import $ from './marker-icon.scss';

type Props = {
    icon: Image;
};

const MarkerIcon: React.FC<Props> = ({ icon }) => (
    <div className={$.icon} style={{ backgroundImage: `url(${icon.url})` }} />
);

export default MarkerIcon;
