import { Usp } from '../models';
import { isString } from '~source/utils/check-type';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Entity = any;

export default function transformUsp(data: Entity): Usp {
    if (!data) throw new Error('Service: no data');

    const id = data.id && isString(data.id);
    const body = data.body && isString(data.body);
    const image = {
        title:
            data.iconImage &&
            data.iconImage.title &&
            isString(data.iconImage.title),
        url:
            data.iconImage &&
            data.iconImage.url &&
            isString(data.iconImage.url),
    };

    return {
        id,
        body,
        image,
    };
}
