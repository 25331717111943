import React from 'react';
import classnames from 'classnames';
import { InputHint } from '~source/ui/components';
import $ from './checkbox.scss';
import { getRandomString } from '~source/utils/utils';

interface Props {
    checked: boolean;
    children?: React.ReactNode;
    className?: string;
    disabled?: boolean;
    footnote?: string;
    hint?: string;
    onChange?: () => void;
    styledAsInput?: boolean;
    title?: string;
}

const Checkbox: React.FunctionComponent<Props> = ({
    checked,
    children,
    className,
    disabled,
    footnote,
    hint,
    onChange,
    styledAsInput = false,
    title,
}: Props) => {
    const checkboxId = getRandomString();

    return (
        <div
            className={classnames([
                $.root,
                checked && $.rootChecked,
                disabled && $.rootDisabled,
                styledAsInput && $.styledAsInput,
                className,
            ])}
        >
            <label htmlFor={checkboxId}>
                <input
                    id={checkboxId}
                    type="checkbox"
                    checked={checked}
                    disabled={disabled}
                    className={$.checkbox}
                    title={title}
                    onChange={() => onChange && onChange()}
                />
                <div className={$.box}>
                    {checked && <span className={$.check} />}
                </div>
                <div className={$.label}>{children}</div>
                {footnote && <span className={$.footnote}>{footnote}</span>}
            </label>
            {hint && <InputHint label={hint} className={$.checkboxHint} />}
        </div>
    );
};

export default Checkbox;
