import { Services, Service } from '../models';
import { isString } from '~source/utils/check-type';
import transformService from './transformService';
import { Module } from '~source/core/models/module';

type Entity = {
    id: string;
    showAsUsp: false;
    title: string;
    topTitle: string;
    body: string;
    layout?: string;
    label?: string;
    serviceItems: Service[];
    modules?: Module[];
};

export default function transformServices(data: Entity): Services {
    if (!data) throw new Error('Services module: no data');

    const id = data.id && isString(data.id);
    const title = data.title && isString(data.title);
    const topTitle = data.topTitle && isString(data.topTitle);
    const body = data.body && isString(data.body);
    const layout = data.layout && isString(data.layout);
    const label = data.label && isString(data.label);
    const modules = data.modules;

    return {
        type: 'services',
        id,
        title,
        topTitle,
        body,
        layout,
        label,
        modules,
        items: data.serviceItems.map(item => transformService(item)),
    };
}
