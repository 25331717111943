import React from 'react';
import Popover from 'react-popover';
import $ from './input-hint.scss';

export interface PasswordHintProps {
    className?: string;
    label: string;
    width?: number;
    height?: number;
    hexColor?: string;
}

const PasswordHint: React.FunctionComponent<PasswordHintProps> = ({
    className = '',
    label,
    width = 20,
    height = 20,
    hexColor = '#404040',
}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const rootRef = React.useRef<any>(null);
    const style = {
        height: `${height}px`,
        width: `${width}px`,
    };
    const svg = {
        __html: `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}"><path fill="${hexColor}" fill-rule="evenodd" d="M10 .833c5.063 0 9.167 4.104 9.167 9.167S15.063 19.167 10 19.167.833 15.063.833 10 4.937.833 10 .833zM10 2.5a7.5 7.5 0 100 15 7.5 7.5 0 000-15zm-.2 10.047c.783 0 1.367.587 1.367 1.366 0 .764-.584 1.351-1.367 1.351-.782 0-1.35-.587-1.35-1.351 0-.779.568-1.366 1.35-1.366zm.077-7.814c1.735 0 3.04.814 3.04 2.625 0 1.904-2.303 2.472-2.119 4.299h-1.98c-.307-2.103 1.934-2.978 1.934-4.13 0-.63-.414-.967-1.028-.967-.568 0-.983.292-1.397.706L7.083 6.13c.691-.829 1.658-1.397 2.794-1.397z"/></svg>`,
    };

    return (
        <Popover
            body={label}
            className={$.inputHintPopover}
            isOpen={isOpen}
            place="above"
            target={rootRef.current}
            tipSize={9}
        >
            <div
                className={className}
                dangerouslySetInnerHTML={svg}
                onMouseEnter={() => {
                    setIsOpen(true);
                }}
                onMouseLeave={() => {
                    setIsOpen(false);
                }}
                ref={rootRef}
                style={style}
            />
        </Popover>
    );
};

export default PasswordHint;
