import React from 'react';
import { TextImageCta as TextImageCtaType } from '~source/core/models';
import {
    TextImageCtaDefault,
    TextImageCtaSmall,
} from '~source/ui/components/modules';

type Props = TextImageCtaType;

const TextImageCta: React.FunctionComponent<Props> = (props: Props) => {
    switch (props.layout) {
        case 'small':
            return <TextImageCtaSmall {...props} />;
        default:
            return <TextImageCtaDefault {...props} />;
    }
};

export default TextImageCta;
