import React from 'react';

import { Icon } from '~source/ui/components';
import { Image } from '~source/core/models';

import $ from './popup-locator.scss';

type Props = {
    email?: string;
    image?: Image;
    phone?: string;
    title: string;
    topTitle: string;
};

const PopupLocatorContact: React.FC<Props> = ({
    email,
    image,
    phone,
    title,
    topTitle,
}) => (
    <div className={$.contact}>
        {image && (
            <div
                style={{ backgroundImage: `url(${image.url})` }}
                className={$.image}
            />
        )}
        <div className={$.details}>
            <span className={$.topTitle}>{topTitle}</span>
            <span className={$.title}>{title}</span>
            {phone && (
                <div className={$.container}>
                    <Icon className={$.phoneIcon} />
                    <a href={`tel:${phone}`} className={$.label}>
                        {phone}
                    </a>
                </div>
            )}
            {email && (
                <div className={$.container}>
                    <Icon className={$.emailIcon} />
                    <a href={`mailto:${email}`} className={$.label}>
                        {email}
                    </a>
                </div>
            )}
        </div>
    </div>
);

export default PopupLocatorContact;
