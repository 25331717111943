import React from 'react';
import { Split as SplitModel, Module } from '~source/core/models';
import { Container, ModuleHandler } from '~source/ui/components';
import $ from './split.scss';

interface Props {
    id: string;
    type: string;
    modulesLeft: Module[];
    modulesRight: Module[];
}

const Split: React.FunctionComponent<SplitModel> = ({
    modulesLeft,
    modulesRight,
}: Props) => (
    <Container className={$.split}>
        <div className={$.col}>
            <ModuleHandler modules={modulesLeft} useContainer={false} />
        </div>
        <div className={$.col}>
            <ModuleHandler modules={modulesRight} useContainer={false} />
        </div>
    </Container>
);

export default Split;
