import React from 'react';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import Analytics, { tracking } from '~source/core/services/analytics';
import SubmenuChevron from '~source/ui/components/submenu-chevron';
import isExternalUrl from '~source/utils/is-external-url';
import $ from './header-menu-item.scss';

interface headerMenuItem {
    englishTitle: string;
    onClick: Function;
    setShowSubmenu: Function;
    setSubmenuTrigger: Function;
    setVisibleSubmenuItems: Function;
    showSubmenu: boolean;
    submenuItems?: [];
    submenuTrigger?: string;
    title: string;
    url: string;
}

const HeaderMenuItem = ({
    englishTitle,
    onClick,
    setShowSubmenu,
    setSubmenuTrigger,
    setVisibleSubmenuItems,
    showSubmenu,
    submenuItems,
    submenuTrigger,
    title,
    url,
}: headerMenuItem) => {
    const clickHandler = React.useCallback(
        subItemTitle => {
            Analytics.event(tracking.events.menu, 'events', {
                category: tracking.categories.menu,
                action: englishTitle,
                label: subItemTitle,
            });
        },
        [englishTitle],
    );

    const onMouseEnterHandler = () => {
        if (submenuItems) {
            setShowSubmenu(true);
            setSubmenuTrigger(url);
            setVisibleSubmenuItems([
                submenuItems.map((item: any, index: any) => {
                    /** React-router's NavLink component can't handle external urls. */
                    if (isExternalUrl(item.url)) {
                        return (
                            <a className={$.headerMenuItem} href={item.url}>
                                {item.title}
                            </a>
                        );
                    } else {
                        return (
                            <NavLink
                                activeClassName={$.headerMenuItemActive}
                                className={$.headerMenuItem}
                                key={index}
                                to={item.url}
                                onClick={() => clickHandler(item.englishTitle)}
                            >
                                {item.title}
                            </NavLink>
                        );
                    }
                }),
            ]);
        }
    };

    const onMouseLeaveHandler = () => {
        setShowSubmenu(false);
    };

    return (
        <NavLink
            activeClassName={$.headerMenuItemActive}
            className={classnames(
                $.headerMenuItem,
                showSubmenu && submenuTrigger === url && $.current,
            )}
            onClick={() => onClick({ title: englishTitle })}
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
            to={url}
        >
            {title}
            {submenuItems && <SubmenuChevron />}
        </NavLink>
    );
};

export default HeaderMenuItem;
