import { Form } from '~source/core/models';
import FormType from '~source/core/models/FormType';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Entity = any;

export default function transformForm(data: Entity): Form {
    if (!data) throw new Error('Form: no data');

    const { backgroundImage, id, endpoint, formType: type } = data;
    const formType: FormType = type.toLowerCase();

    /** Actively typeguarding the FormType type.  */
    if (
        formType !== 'contact' &&
        formType !== 'login' &&
        formType !== 'registration' &&
        formType !== 'registrationv2' &&
        formType !== 'registrationeventflorist' &&
        formType !== 'registrationstoreownerflorist'
    ) {
        throw new Error(`Form: invalid form type ${formType}.`);
    }

    const postUrl = endpoint || null;
    const title = data.title || '';
    const subtitle = data.topTitle || '';
    const body = data.body || '';
    const countries =
        data.countries &&
        data.countries.map(item => ({ id: item.id, title: item.title }));

    return {
        backgroundImage,
        type: 'form',
        id,
        formType,
        postUrl,
        title,
        subtitle,
        body,
        countries,
    };
}
