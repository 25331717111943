import { Image, Testimonial, TestimonialCard } from '~source/core/models';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Entity = any;

function transformImage(data: Entity): Image {
    return {
        id: data.id,
        title: data.title,
        url: data.url,
    };
}

function transformCard(data: Entity, index: number): TestimonialCard {
    return {
        customerName: data.customerName,
        id: index,
        image: data.image && transformImage(data.image),
        quote: data.quote,
    };
}

export default function transformTestimonial(data: Entity): Testimonial {
    return {
        cards: data.cards.map((card: Entity, index: number) =>
            transformCard(card, index),
        ),
        id: data.id,
        tentative: data.tentative,
        title: data.title,
        subtitle: data.topTitle,
        type: 'testimonial',
    };
}
