import * as React from 'react';
import { Usp } from '~source/core/models';
import $ from './usp-list.scss';
import { Container, HtmlText } from '~source/ui/components';

type Props = {
    items: Usp[];
};

const UspList: React.FunctionComponent<Props> = (props: Props) => {
    const { items } = props;
    const item = (data: Usp) => {
        const { body, image } = data;

        return (
            <div className={$.item} key={`${image.url}_${body}`}>
                <section className={$.content}>
                    <img src={image.url} alt={image.title} />
                    <HtmlText content={body} className={$.text} />
                </section>
            </div>
        );
    };

    return (
        <Container>
            <section className={$.wrap}>{items.map(v => item(v))}</section>
        </Container>
    );
};

export default UspList;
