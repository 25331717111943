import React from 'react';

type ValidationFunction = (value: string) => boolean;
type HookValue = {
    value: string;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    valid: boolean;
};

const defaultValidate: ValidationFunction = () => true;

function useFormValue(
    initialValue: string,
    validate: ValidationFunction = defaultValidate,
): HookValue {
    const [value, setValue] = React.useState(initialValue);
    const validateRef = React.useRef(validate);
    const valid = React.useMemo(() => {
        return validateRef.current(value.trim());
    }, [value]);
    const onChange: HookValue['onChange'] = React.useCallback(ev => {
        setValue(ev.target.value);
    }, []);

    return {
        value,
        onChange,
        setValue,
        valid,
    };
}

export default useFormValue;
