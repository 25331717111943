import * as React from 'react';
import classnames from 'classnames';

import { Button, Map } from '~source/ui/components';
import { Map as MapType } from '~source/core/models';
import useLabel from '~source/ui/hooks/use-label';

import $ from './map.scss';

interface Props extends MapType {
    className?: string;
}

const MapContact: React.FC<Props> = ({ className, lat, lng }: Props) => {
    const center = { lat, lng };
    const options = {
        fullscreenControl: false,
        zoomControl: false,
    };
    const buttonLabel = useLabel('Contact/MapsRoute');

    const renderMarker = (map, maps) => {
        // eslint-disable-next-line no-new
        new maps.Marker({
            map,
            position: new maps.LatLng(lat, lng),
        });
    };

    return (
        <section className={classnames($.map, className)}>
            <Map
                center={center}
                onLoaded={({ map, maps }) => renderMarker(map, maps)}
                options={options}
                zoom={14}
            />
            <Button
                className={$.button}
                to={`http://www.google.com/maps/place/${lat},${lng}`}
            >
                {buttonLabel}
                <img
                    className={$.buttonIcon}
                    src="/static/icons/navigation.svg"
                    alt=""
                />
            </Button>
        </section>
    );
};

export default MapContact;
