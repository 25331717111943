import PageView from './views/page';
import ErrorView from './views/error';

export const paths = {
    error: '/500',
    page: '*',
};

export const routes = [
    {
        path: paths.error,
        component: ErrorView,
    },
    {
        path: paths.page,
        component: PageView,
    },
];
