import * as React from 'react';
import { InitContext } from '~source/ui/context';
import { Button, HtmlText, Link, Image } from '~source/ui/components';
import { useWindowWidth } from '~source/ui/hooks';
import breakpoints from '~source/core/constants/breakpoints';
import $ from './footer-card.scss';

const FooterCard: React.FunctionComponent = () => {
    const init = React.useContext(InitContext);
    const windowWidth = useWindowWidth();
    if (!init) return null;
    const { footerCard } = init;
    if (!footerCard) return null;
    const { body, link, title, foregroundImage } = footerCard;
    const isMobile = windowWidth < breakpoints.large;
    const imageWidth = isMobile ? 122 : 190;

    return (
        <div className={$.footerCard}>
            <div className={$.text}>
                {title && <div className={$.title}>{title}</div>}
                {body && <HtmlText className={$.body} content={body} />}
                {link && link.title && link.url && (
                    <Link to={link.url}>
                        <Button>{link.title}</Button>
                    </Link>
                )}
            </div>

            {foregroundImage && foregroundImage.url && (
                <div className={$.imageContainer}>
                    <Image
                        placeholder={`${foregroundImage.url}?w=30&q=50`}
                        src={`${foregroundImage.url}?w=${imageWidth}&q=75`}
                        alt={foregroundImage.title}
                    />
                </div>
            )}
        </div>
    );
};

export default FooterCard;
