import React from 'react';
import { debounce } from 'lodash';

function isSnappedInParent(parent: HTMLElement, child: HTMLElement) {
    const parentRect = parent.getBoundingClientRect();
    const childRect = child.getBoundingClientRect();

    const childHeight = childRect.height;
    const childWidth = childRect.height;

    const childTop = childRect.top - parentRect.top;
    const childBottom = childTop + childHeight;
    const childLeft = childRect.left - parentRect.left;
    const childRight = childLeft + childWidth;

    return (
        childTop < childHeight / 2 &&
        childBottom > childHeight / 2 &&
        childLeft < childWidth / 2 &&
        childRight > childWidth / 2
    );
}

export default function useSnappedIndex(
    parentRef: React.RefObject<HTMLElement>,
    onIndexSnapped: (index: number) => void,
) {
    const snapToIndex = (index: number) => {
        const parent = parentRef.current;
        if (!parent) return;

        const child = parent.children[index] as HTMLElement;
        if (child) parent.scrollLeft = child.offsetLeft;
    };

    React.useEffect(() => {
        const parent = parentRef.current;
        if (!parent) return undefined;

        const handleScroll = debounce(() => {
            const { children } = parent;

            for (let i = 0; i < children.length; i += 1) {
                const child = children[i] as HTMLElement;

                if (isSnappedInParent(parent, child)) {
                    onIndexSnapped(i);
                }
            }
        }, 100);

        parent.addEventListener('scroll', handleScroll);

        return () => {
            parent.removeEventListener('scroll', handleScroll);
        };
    }, [parentRef, onIndexSnapped]);

    return {
        snapToIndex,
    };
}
