/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
export function isString(value: any) {
    if (typeof value === 'string') {
        return value;
    }

    console.error(`no string: ${typeof value}`);
    return '';
}

export function isBool(value: any) {
    if (typeof value === 'boolean') {
        return value;
    }

    console.error(`no boolean: ${typeof value}`);
    return false;
}

export function isCategory(value: any) {
    if (
        typeof value.title === 'string' &&
        typeof value.slug === 'string' &&
        (value.path && typeof value.path === 'string')
    ) {
        return value;
    }

    console.error(`no string: ${typeof value.title} or ${typeof value.slug}`);
    return {};
}

export function isObject(value: any) {
    if (typeof value === 'object') {
        return value;
    }

    console.error(`no object: ${typeof value}`);
    return {};
}

export function isArray(value: any) {
    if (typeof value === 'object') {
        return value;
    }

    console.error(`no object: ${typeof value}`);
    return [];
}

export function isNum(value: any) {
    if (typeof value === 'number') {
        return value;
    }

    console.error(`no number: ${typeof value}`);
    return 0;
}

export function isImage(value: any) {
    if (typeof value.url === 'string' && typeof value.title === 'string') {
        return value;
    }

    console.error(
        `no string: url:${typeof value.url} or title:${typeof value.title}`,
    );
    return {};
}

export function isButton(value: any) {
    if (
        value.title &&
        typeof value.title === 'string' &&
        (value.slug && typeof value.slug === 'string') &&
        (value.externalUrl && typeof value.externalUrl === 'string')
    ) {
        return value;
    }

    console.error(
        `no string: title:${typeof value.title} or slug:${typeof value.slug} or externalUrl:${typeof value.externalUrl}`,
    );
    return {};
}
