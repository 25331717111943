import React from 'react';
import classnames from 'classnames';
import Cookies from 'js-cookie';

import { Button, Checkbox, HtmlText } from '~source/ui/components';
import useLabel from '~source/ui/hooks/use-label';
import Analytics, { tracking } from '~source/core/services/analytics';

import $ from './cookie-accept.scss';

const COOKIE_NAME_SHOP = 'fleurametz-accept-cookies-shop';
const COOKIE_NAME_SITE = 'fleurametz-accept-cookies-site';
const COOKIE_EXPIRE = 99999;
const COOKIE_DEFAULT = {
    basic: true,
    statistics: true,
    marketing: true,
};

const acceptCookie = (cookieValue: string) => {
    const domain = window.location.host
        .split('.')
        .slice(-2)
        .join('.');
    const cookieNames = [COOKIE_NAME_SHOP, COOKIE_NAME_SITE];
    cookieNames.forEach(cookieName => {
        // To have the cookie work on localhost as well, the domain has to be
        // ommitted completely. Setting it to null, undefined or an empty
        // string won't work.
        if (window.location.host.indexOf('localhost') !== -1) {
            Cookies.set(cookieName, cookieValue, {
                expires: COOKIE_EXPIRE,
            });
        } else {
            Cookies.set(cookieName, cookieValue, {
                expires: COOKIE_EXPIRE,
                domain: `.${domain}`, // set for top level domain and all sub domains
            });
        }
    });
};

type Props = {
    forceShow?: boolean;
};

const CookieAccept: React.FunctionComponent<Props> = ({ forceShow }: Props) => {
    const labels = {
        intro: useLabel('General/Cookie/Explain'),
        toggleButton: useLabel('Cookie/ToggleButton'),
        toggleText: useLabel('Cookie/ToggleText'),
        acceptAllButton: useLabel('Cookie/AcceptAllButton'),
        acceptButton: useLabel('Cookie/AcceptButton'),
        cookieBasic: useLabel('Cookie/Basic'),
        cookieStatistics: useLabel('Cookie/Statistics'),
        cookieStatisticsFootnote: useLabel('Cookie/StatisticsFootnote'),
        cookieMarketing: useLabel('Cookie/Marketing'),
        cookieMarketingFootnote: useLabel('Cookie/MarketingFootnote'),
    };

    const [showCheckboxes, setShowCheckboxes] = React.useState(false);
    const [show, setShow] = React.useState(true);
    const [consent, setConsent] = React.useState(COOKIE_DEFAULT);

    const domain = window.location.host
        .split('.')
        .slice(-2)
        .join('.');

    const toggleCheckboxes = () => {
        if (showCheckboxes) {
            // now closing, reset the cookies
            setConsent(COOKIE_DEFAULT);
        }
        setShowCheckboxes(!showCheckboxes);
    };

    const handleSaveCookie = () => {
        // Value example: "basic,statistic,marketing".
        const cookieValue = Object.entries(consent)
            .map(value => (value[1] ? value[0] : false))
            .filter(value => value)
            .join(',');

        acceptCookie(cookieValue);

        setShow(false);

        Analytics.event(tracking.events.cookie, 'cookie', {
            basic: consent.basic,
            statistics: consent.statistics,
            marketing: consent.marketing,
        });
    };

    React.useEffect(() => {
        const acceptedCookie =
            Cookies.get(COOKIE_NAME_SITE) && Cookies.get(COOKIE_NAME_SHOP);
        if (acceptedCookie && !forceShow) setShow(false);
    }, [forceShow]);

    if (!show) return null;

    return (
        <section className={$.wrap}>
            <div className={$.inner}>
                <HtmlText className={$.text} content={labels.intro} />
                <Button
                    className={classnames([
                        $.button,
                        $.toggleButton,
                        showCheckboxes && $.open,
                    ])}
                    onClick={() => toggleCheckboxes()}
                    color="white"
                    textLight
                >
                    {labels.toggleButton} <span className={$.chevron} />
                </Button>
                {!showCheckboxes && (
                    <Button
                        className={$.button}
                        onClick={() => handleSaveCookie()}
                    >
                        {labels.acceptAllButton}
                    </Button>
                )}

                {showCheckboxes && (
                    <div className={$.wrapper}>
                        <hr className={$.hr} />
                        <p className={$.text}>{labels.toggleText}</p>
                        <div className={$.checkboxes}>
                            <Checkbox
                                checked
                                disabled
                                title={labels.cookieBasic}
                                className={$.checkbox}
                            >
                                {labels.cookieBasic}
                            </Checkbox>
                            <Checkbox
                                checked={consent.statistics}
                                title={labels.cookieStatistics}
                                footnote={labels.cookieStatisticsFootnote}
                                className={$.checkbox}
                                onChange={() =>
                                    setConsent({
                                        ...consent,
                                        statistics: !consent.statistics,
                                    })
                                }
                            >
                                {labels.cookieStatistics}
                            </Checkbox>
                            <Checkbox
                                checked={consent.marketing}
                                title={labels.cookieMarketing}
                                footnote={labels.cookieMarketingFootnote}
                                className={$.checkbox}
                                onChange={() =>
                                    setConsent({
                                        ...consent,
                                        marketing: !consent.marketing,
                                    })
                                }
                            >
                                {labels.cookieMarketing}
                            </Checkbox>
                        </div>
                        <Button onClick={() => handleSaveCookie()}>
                            {labels.acceptButton}
                        </Button>
                    </div>
                )}
            </div>
        </section>
    );
};

export default CookieAccept;
