import * as React from 'react';
import GoogleMapReact, { Coords, MapOptions } from 'google-map-react';

import { ConfigContext } from '~source/ui/context';

type Props = {
    center: Coords;
    children?: React.ReactNode;
    onChange?: ({ zoom, bounds }) => void;
    onLoaded: ({ map, maps }) => void;
    options: MapOptions;
    zoom: number;
};

const Map: React.FC<Props> = ({
    center,
    children,
    onChange,
    onLoaded,
    options,
    zoom,
}: Props) => {
    const config = React.useContext(ConfigContext);

    return (
        <>
            <GoogleMapReact
                bootstrapURLKeys={{ key: config.mapsApiKey }}
                defaultCenter={center}
                defaultZoom={zoom}
                onChange={onChange}
                onGoogleApiLoaded={onLoaded}
                options={options}
                yesIWantToUseGoogleMapApiInternals
            >
                {children}
            </GoogleMapReact>
        </>
    );
};

export default Map;
