import React from 'react';
import { Link } from 'react-router-dom';
import isExternalUrl from '~source/utils/is-external-url';

export default ({ to, children, ...props }) => {
    // Nothing to link to
    if (!to) return <span {...props}>{children}</span>;

    // External link
    if (isExternalUrl(to))
        return (
            <a href={to} target="_blank" rel="noopener noreferrer" {...props}>
                {children}
            </a>
        );
    // Internal link
    return (
        <Link to={to} {...props}>
            {children}
        </Link>
    );
};
