import * as React from 'react';
import { useLocation } from 'react-router-dom';
import Analytics, { tracking } from '~source/core/services/analytics';
import Page from '~source/core/models/page';
import { ConfigContext } from '../context';
import { getCookie } from '~source/utils/get-cookie';
import { setCookie } from '~source/utils/set-cookie';
import { makeid } from '~source/utils/make-id';

export default function usePageViews(data: Page | null) {
    const [prevTitle, setPrevTitle] = React.useState('');
    const location = useLocation();
    const configContext = React.useContext(ConfigContext);
    const { cultureId, initialCultureId } = configContext;

    const trackPage = React.useMemo(
        () => () => {
            if (data) {
                const currentPathname = location.pathname;
                const { analyticsId, title } = data;

                if (title !== prevTitle) {
                    // create valid session cookie if not exist
                    if (!getCookie('fm_session')) {
                        // expiration time based on a day
                        const halfHour = 0.02083333333;
                        // set session cookie
                        setCookie('fm_session', makeid(12), halfHour);
                    }

                    Analytics.event(
                        tracking.events.pageView,
                        'page',
                        {
                            name: analyticsId,
                            url: currentPathname,
                            countryCode: cultureId || initialCultureId,
                        },
                        {
                            clientId: getCookie('_ga'),
                            sessionId: getCookie('fm_session'),
                            trafficType: '',
                        },
                    );

                    setPrevTitle(title);
                }
            }

            window.scrollTo(0, 0);
        },
        [cultureId, data, initialCultureId, location.pathname, prevTitle],
    );

    return { trackPage };
}
