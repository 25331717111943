import * as React from 'react';
import { InitContext } from '~source/ui/context';
import { Icon } from '~source/ui/components';
import $ from './language-switch.scss';

type Props = {
    togglePopOver: () => void;
};

const LanguageSwitch: React.FunctionComponent<Props> = (props: Props) => {
    const init = React.useContext(InitContext);
    if (!init) return null;
    const { cultures } = init;
    if (!cultures) return null;
    const currentCulture = cultures.find(c => !!c.isActive);
    if (!currentCulture) return null;
    const { id } = currentCulture;
    const countryCode = id.substring(3, 5);
    const { togglePopOver } = props;

    return (
        <section>
            <div
                className={$.languageSwitch}
                onClick={togglePopOver}
                onKeyDown={() => {}}
                role="button"
                tabIndex={-1}
            >
                <Icon className={$.world} size="small" />
                <span className={$.countryCode}>{countryCode}</span>
                <Icon className={$.downGray} size="mini" />
            </div>
        </section>
    );
};

export default LanguageSwitch;
