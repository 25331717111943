export const moduleLabels: {
    articleGrid: 'articleGrid';
    contact: 'contact';
    featured: 'featured';
    form: 'form';
    html: 'html';
    image: 'image';
    imagePin: 'imagePin';
    inspirationPackage: 'inspirationPackage';
    locator: 'locator';
    services: 'services';
    split: 'split';
    testimonial: 'testimonial';
    text: 'text';
    textImage: 'text-image';
    textImageCta: 'text-image-cta';
    uspList: 'uspList';
    vacancies: 'vacancies';
    video: 'video';
} = {
    articleGrid: 'articleGrid',
    contact: 'contact',
    featured: 'featured',
    form: 'form',
    html: 'html',
    image: 'image',
    imagePin: 'imagePin',
    inspirationPackage: 'inspirationPackage',
    locator: 'locator',
    services: 'services',
    split: 'split',
    testimonial: 'testimonial',
    text: 'text',
    textImage: 'text-image',
    textImageCta: 'text-image-cta',
    uspList: 'uspList',
    vacancies: 'vacancies',
    video: 'video',
};

const moduleTypes = {
    [moduleLabels.articleGrid]: 'a20',
    [moduleLabels.contact]: 'a38',
    [moduleLabels.featured]: 'a8',
    [moduleLabels.form]: 'a23',
    [moduleLabels.html]: 'a40',
    [moduleLabels.image]: 'a15',
    [moduleLabels.imagePin]: 'a60',
    [moduleLabels.inspirationPackage]: 'a62',
    [moduleLabels.locator]: 'a64',
    [moduleLabels.services]: 'a22',
    [moduleLabels.split]: 'a48',
    [moduleLabels.testimonial]: 'a65',
    [moduleLabels.text]: 'a13',
    [moduleLabels.textImage]: 'a12',
    [moduleLabels.textImageCta]: 'a7',
    [moduleLabels.uspList]: 'a25',
    [moduleLabels.vacancies]: 'a30',
    [moduleLabels.video]: 'a10',
};

export default moduleTypes;
