import { Card } from '~source/core/models';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Entity = any;

export default function transformInit(data: Entity) {
    if (!data) throw new Error('Service: no data');

    const myFmPopupCard =
        data && data.myFmPopupCard && new Card(data.myFmPopupCard);

    return {
        ...data,
        myFmPopupCard,
    };
}
