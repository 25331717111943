import React from 'react';
import classnames from 'classnames';

import { Icon } from '~source/ui/components';
import { useWindowWidth } from '~source/ui/hooks';
import breakpoints from '~source/core/constants/breakpoints';

import $ from './popup-locator.scss';

type Props = {
    align: 'left' | 'right' | 'center';
    mobileOffset: number;
    onClose: () => void;
    side: 'top' | 'bottom';
};

const PopupLocator: React.FC<Props> = ({
    align,
    children,
    mobileOffset,
    onClose,
    side,
}) => {
    const windowWidth = useWindowWidth();
    const isDesktop = windowWidth >= breakpoints.large;

    // Workaround for some old mobile Android devices that
    // align the popover incorrectly on the first render
    const [left, setLeft] = React.useState<string | undefined>(undefined);
    React.useEffect(() => {
        if (isDesktop) return;
        setLeft(`${mobileOffset}%`);
    }, []);

    return (
        <div
            className={classnames(
                side === 'top' && $.top,
                side === 'bottom' && $.bottom,
                align === 'left' && isDesktop && $.alignLeft,
                align === 'right' && isDesktop && $.alignRight,
                align === 'center' && isDesktop && $.alignCenter,
                !left && !isDesktop && $.transparent,
            )}
        >
            <div className={$.popup} style={{ left }}>
                {children}
                <button className={$.close} type="button" onClick={onClose}>
                    <Icon className={$.crossIcon} size="small" />
                </button>
            </div>
            <div className={$.triangle} />
            <div className={$.popupShadow} style={{ left }} />
            <div className={$.triangleShadow} />
        </div>
    );
};

export default PopupLocator;
