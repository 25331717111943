import * as React from 'react';
import classnames from 'classnames';
import $ from './language-switch-menu.scss';
import { InitContext, ConfigContext } from '~source/ui/context';
import { Culture } from '~source/core/models';

type CultureButtonType = Culture & {
    changeCulture: (id: string) => void;
};

const LanguageButton: React.FunctionComponent<CultureButtonType> = props => {
    const { id, language, isActive, changeCulture } = props;
    const clickHandler = React.useCallback(() => {
        if (isActive) return;
        changeCulture(id);
    }, [changeCulture, id, isActive]);
    const style = classnames($.culture, isActive && $.cultureActive);

    return (
        <li className={style}>
            <button onClick={clickHandler} type="button" className={$.button}>
                <span className={$.language}>{language}</span>
            </button>
        </li>
    );
};

type Props = {
    visible: boolean;
};

const LanguageSwitchMenu: React.FunctionComponent<Props> = (props: Props) => {
    const init = React.useContext(InitContext);
    const config = React.useContext(ConfigContext);
    const onCultureChange = React.useCallback(
        (id: string) => {
            if (config) {
                
                // TODO: Fix type
                // @ts-ignore
                config.setCultureId(id);
            }
        },
        [config],
    );

    if (!init || !init.cultures) return null;

    const { cultures } = init;
    const { visible } = props;

    return (
        <div
            className={classnames(
                $.languageSwitchMenu,
                visible && $.languageSwitchMenuVisible,
            )}
        >
            <div className={$.box}>
                <div className={$.scroll}>
                    <div className={$.triangle} />
                    <ul>
                        {cultures.map(culture => (
                            <LanguageButton
                                key={culture.id}
                                {...culture}
                                changeCulture={onCultureChange}
                            />
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default LanguageSwitchMenu;
