import * as React from 'react';
import Page from '~source/core/models/page';
import breakpoints from '~source/core/constants/breakpoints';
import { ArticleTeaser as ArticleTeaserModel } from '~source/core/models/article-teaser';
import {
    ArticleTeaser,
    Container,
    ModuleHandler,
    PageNavigation,
    Title,
} from '~source/ui/components';
import $ from './category.scss';
import useArticleService from '~source/ui/hooks/use-article-service';
import useInView from '~source/ui/hooks/use-in-view';
import useLabel from '~source/ui/hooks/use-label';
import useWindowWidth from '~source/ui/hooks/use-window-width';

type Props = Page;

const Category: React.FunctionComponent<Props> = ({
    featuredItems,
    items,
    next,
    pageType,
    modules,
    tabs,
    title,
    topTitle,
}) => {
    const [visibleItems, setVisibleItems] = React.useState<
        ArticleTeaserModel[]
    >(items);
    const [hasError, setHasError] = React.useState<boolean>(false);
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < breakpoints.medium;
    const trigger = React.useRef() as React.MutableRefObject<HTMLDivElement>;
    const isInView = useInView(trigger, 1.5);
    const errorLabel = useLabel('Website/ServerUnresponsive');

    const { articles, articleError, fetchArticles } = useArticleService(
        visibleItems,
        next,
    );

    React.useEffect(() => {
        if (isInView) {
            fetchArticles();
        }
    }, [isInView]);

    React.useEffect(() => {
        setVisibleItems(articles);
    }, [articles]);

    React.useEffect(() => {
        setHasError(articleError);
    }, [articleError]);

    return (
        <section>
            <div className={$.header}>
                <Container>
                    <>
                        <Title title={title} subtitle={topTitle} />
                        <PageNavigation links={tabs} type="horizontal" />
                    </>
                </Container>
            </div>

            {pageType === 'lookbook' && modules && modules.length > 0 && (
                <Container>
                    <ModuleHandler className={$.modules} modules={modules} />
                </Container>
            )}

            {pageType !== 'lookbook' && (
                <Container>
                    <>
                        <section className={$.featuredScroll}>
                            <div className={$.featuredContainer}>
                                {featuredItems.map((item, index) => (
                                    <ArticleTeaser
                                        key={item.url}
                                        {...item}
                                        layout="card"
                                        className={$.featuredArticle}
                                        size={
                                            !isMobile && index === 0
                                                ? 'large'
                                                : 'small'
                                        }
                                    />
                                ))}
                            </div>
                        </section>
                        <section className={$.container}>
                            <div className={$.listContainer}>
                                {visibleItems.map(item => (
                                    <ArticleTeaser
                                        key={item.url}
                                        {...item}
                                        layout="list-item"
                                        className={$.listArticle}
                                    />
                                ))}
                            </div>
                            <aside>
                                {/* TODO: remove css line from container classname
                            if we are gonna use the sidebar.
                            Space for: 'Uitgelicht' and 'Tags' */}
                            </aside>
                        </section>
                        {hasError && (
                            <div className={$.message}>{errorLabel}</div>
                        )}
                        <div ref={trigger} />
                    </>
                </Container>
            )}
        </section>
    );
};

export default Category;
