import * as React from 'react';
import fetchInit from '~source/core/services/fetch-init';
import { ConfigContextType } from '../context/config-context';
import { InitContextType } from '../context/init-context';

interface ReturnProps {
    initData: InitContextType | null;
    isRedirect: boolean;
    pending: boolean;
}

export default function useInitService(config: ConfigContextType): ReturnProps {
    const [isRedirect, setIsRedirect] = React.useState(true);
    const [pending, setPending] = React.useState(true);
    const [initData, setInitData] = React.useState<InitContextType | null>(
        null,
    );

    const fetchData = React.useCallback(async () => {
        setPending(true);
        setIsRedirect(false);

        const response = await fetchInit(config);

        if (response.isRedirect) {
            setIsRedirect(true);
        } else {
            setInitData(response);
        }

        setPending(false);

        return null;
    }, [config]);

    React.useEffect(() => {
        fetchData();
    }, [fetchData, config.cultureId]);

    return { initData, isRedirect, pending };
}
