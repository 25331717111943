import * as React from 'react';
import classnames from 'classnames';
import $ from './profile.scss';
import { Profile as ProfileType } from '~source/core/models';

interface Props extends ProfileType {
    className?: string;
    jobTitle?: string;
    title: string;
}

const Profile: React.FunctionComponent<Props> = ({
    className,
    image,
    jobTitle,
    name,
    title,
}: Props) => {
    return (
        <section className={classnames($.profile, className && className)}>
            {image && image.url && (
                <div className={$.profileImage}>
                    <img src={image.url} alt={image.title} />
                </div>
            )}
            <div className={$.profileInfo}>
                <span className={$.profileInfoTitle}>{title}:</span>
                <span className={$.profileInfoName}>{name}</span>
                {jobTitle && (
                    <span className={$.profileInfoJob}>{jobTitle}</span>
                )}
            </div>
        </section>
    );
};

export default Profile;
