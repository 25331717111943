import React from 'react';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';
import { StyledFont } from '~source/core/models';
import $ from './title.scss';

type Props = {
    className?: string;
    color?: string;
    light?: boolean;
    reverseTitles?: boolean;
    size?: 'smaller' | 'small' | 'medium' | 'large';
    subtitle?: string;
    title?: string;
    titleFont?: StyledFont;
};

const Title: React.FunctionComponent<Props> = ({
    className,
    color,
    light,
    reverseTitles,
    size,
    subtitle,
    title,
    titleFont,
}) => {
    const titleStyle = classnames(
        $.container,
        size && size === 'smaller' && $.containerSmaller,
        size && size === 'small' && $.containerSmall,
        size && size === 'medium' && $.containerMedium,
        size && size === 'large' && $.containerLarge,
        className,
    );

    let styledFont;
    if (titleFont) {
        styledFont = {
            fontWeight: titleFont.isBold ? 'bold' : 'inherit',
            textTransform: titleFont.isAllCaps ? 'uppercase' : 'inherit',
            fontStyle: titleFont.isItalic ? 'italic' : 'inherit',
        };

        if (titleFont.color) {
            styledFont = {
                ...styledFont,
                color: `${titleFont.color}`,
            };
        }

        if (titleFont.fontLink && titleFont.fontLink.title) {
            styledFont = {
                ...styledFont,
                fontFamily: `'${titleFont.fontLink.title}', sans-serif`,
            };
        }
    }

    return (
        <React.Fragment>
            {titleFont && titleFont.fontLink && titleFont.fontLink.url && (
                <Helmet>
                    <link href={titleFont.fontLink.url} rel="stylesheet" />
                </Helmet>
            )}
            {(title || subtitle) && (
                <div className={titleStyle} style={{ color }}>
                    {title && (
                        <h2 className={classnames($.title)} style={styledFont}>
                            {title}
                        </h2>
                    )}
                    {subtitle && (
                        <h3
                            className={classnames(
                                $.subtitle,
                                light && $.subtitleLight,
                                reverseTitles && title && $.subtitleReversed,
                            )}
                            style={{ color }}
                        >
                            {subtitle}
                        </h3>
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

export default Title;
