import {
    ArticleTeaser,
    Author,
    Header,
    Image,
    OtherPackagesCarousel,
    Link,
    MenuItem,
    Module,
    Tag,
} from '.';

export default class Page {
    analyticsId: string;
    articleTags?: Tag[];
    author?: Author;
    featuredItems: ArticleTeaser[];
    header?: Header;
    headerImage?: Image;
    id: string;
    introduction?: string;
    isLargeHeaderImage?: boolean;
    items: ArticleTeaser[];
    modules: Module[];
    next?: string;
    otherPackagesCarousel?: OtherPackagesCarousel;
    pageType?: string;
    publishTime?: string;
    relatedItems?: ArticleTeaser[];
    seoDescription: string;
    seoKeywords: string;
    sidebarLinks?: MenuItem[];
    tabs: Link[];
    title: string;
    topTitle: string;
    type: string;
    url: string;

    constructor(data: Page) {
        this.analyticsId = data.analyticsId;
        this.articleTags = data.articleTags;
        this.author = data.author;
        this.featuredItems = data.featuredItems;
        this.header = data.header;
        this.headerImage = data.headerImage;
        this.id = data.id;
        this.introduction = data.introduction;
        this.isLargeHeaderImage = data.isLargeHeaderImage;
        this.items = data.items;
        this.modules = data.modules;
        this.next = data.next;
        this.otherPackagesCarousel = data.otherPackagesCarousel;
        this.pageType = data.pageType;
        this.publishTime = data.publishTime;
        this.relatedItems = data.relatedItems;
        this.seoDescription = data.seoDescription;
        this.seoKeywords = data.seoKeywords;
        this.sidebarLinks = data.sidebarLinks;
        this.tabs = data.tabs;
        this.title = data.title;
        this.topTitle = data.topTitle;
        this.type = data.type;
        this.url = data.url;
    }
}
