import * as React from 'react';
import * as ReactDOM from 'react-dom';
import classnames from 'classnames';

import { ConfigContext } from '~source/ui/context';
import { Coordinates } from '~source/core/models';
import { Icon } from '~source/ui/components';
import { useWindowWidth } from '~source/ui/hooks';
import breakpoints from '~source/core/constants/breakpoints';

import Map from './map';
import $ from './map.scss';

type Props = {
    center: Coordinates;
    children: React.ReactNode;
    className?: string;
    onChange: ({ zoom, bounds }) => void;
    onLoaded: ({ map, maps }) => void;
    zoom: number;
};

const geolocationZoom = 8;

const MapLocator: React.FC<Props> = ({
    center,
    children,
    className,
    onChange,
    onLoaded,
    zoom,
}: Props) => {
    const windowWidth = useWindowWidth();
    const isDesktop = windowWidth >= breakpoints.large;

    const config = React.useContext(ConfigContext);
    const options = {
        mapId: config.mapsLocatorMapId,
        zoomControl: isDesktop,
        fullscreenControl: false,
    };

    const centerMapUsingGeolocation = map => {
        navigator.geolocation.getCurrentPosition(location => {
            const { latitude, longitude } = location.coords;
            const center = new google.maps.LatLng(latitude, longitude);
            map.setCenter(center);
            map.setZoom(geolocationZoom);
        });
    };

    const onLoadedHandler = ({ map, maps }) => {
        const GPSControl = (
            <div
                onClick={() => centerMapUsingGeolocation(map)}
                className={$.gpsControl}
            >
                <Icon className={$.gpsIcon} size="small" />
            </div>
        );

        const GPSControlElement = document.createElement('div');
        ReactDOM.render(GPSControl, GPSControlElement);

        map.controls[maps.ControlPosition.RIGHT_BOTTOM].push(GPSControlElement);

        onLoaded({ map, maps });
    };

    return (
        <section className={classnames($.locatorMap, className)}>
            <Map
                center={center}
                onChange={onChange}
                onLoaded={onLoadedHandler}
                options={options}
                zoom={zoom}
            >
                {children}
            </Map>
        </section>
    );
};

export default MapLocator;
