import React from 'react';
import classNames from 'classnames';

import {
    MarkerIcon,
    PopupLocator,
    PopupLocatorLocation,
    PopupLocatorPreferredSupplier,
    PopupLocatorContact,
} from 'source/ui/components';
import {
    MarkerContact,
    MarkerLocation,
    MarkerPreferredSupplier,
} from 'source/core/models';
import { useWindowWidth } from 'source/ui/hooks';
import breakpoints from 'source/core/constants/breakpoints';

import $ from './map-marker.scss';

const MIN_POPUP_OFFSET = -40;
const MAX_POPUP_OFFSET = 40;

type Props = {
    mapBounds?: [number, number, number, number];
    mapZoom: number;
    marker: MarkerLocation | MarkerPreferredSupplier | MarkerContact;
    removeActiveHighlights: () => void;
    setActiveHighlights: (countries) => void;
    // lat and lng not being used in this component
    // but needed because GoogleMapReact uses it
    lat: number;
    lng: number;
};

const MapMarker: React.FC<Props> = ({
    mapBounds,
    mapZoom,
    marker,
    removeActiveHighlights,
    setActiveHighlights,
}) => {
    const windowWidth = useWindowWidth();
    const isDesktop = windowWidth >= breakpoints.large;

    const [popupVisible, setPopupVisible] = React.useState(false);

    const mouseEnterTimeout = React.useRef<number>();
    const mouseLeaveTimeout = React.useRef<number>();

    const openHandler = () => {
        setPopupVisible(true);

        if (marker.type === 'contactPerson')
            setActiveHighlights(marker.countries);

        mouseEnterTimeout.current &&
            window.clearTimeout(mouseEnterTimeout.current);
        mouseLeaveTimeout.current &&
            window.clearTimeout(mouseLeaveTimeout.current);
    };

    const closeHandler = () => {
        setPopupVisible(false);

        if (marker.type === 'contactPerson') removeActiveHighlights();

        mouseEnterTimeout.current &&
            window.clearTimeout(mouseEnterTimeout.current);
        mouseLeaveTimeout.current &&
            window.clearTimeout(mouseLeaveTimeout.current);
    };

    const mouseEnterHandler = () => {
        mouseLeaveTimeout.current &&
            window.clearTimeout(mouseLeaveTimeout.current);

        const timeout = window.setTimeout(() => {
            openHandler();
        }, 100);
        mouseEnterTimeout.current = timeout;
    };

    const mouseLeaveHandler = () => {
        mouseEnterTimeout.current &&
            window.clearTimeout(mouseEnterTimeout.current);

        const timeout = window.setTimeout(() => {
            closeHandler();
        }, 300);
        mouseLeaveTimeout.current = timeout;
    };
    const decidePopupSide = () => {
        if (!mapBounds) return 'top';

        const { lat } = marker.coordinates;

        const spaceTop = Math.abs(mapBounds[3] - lat);
        const spaceBottom = Math.abs(mapBounds[1] - lat);

        return spaceTop > spaceBottom ? 'top' : 'bottom';
    };

    const decidePopupAlign = () => {
        if (!mapBounds) return 'right';

        const { lng } = marker.coordinates;

        // create an offset of 400 px when the legend is visible
        // because this space should not be used by the popup
        // convert px to longitude offset based on the current zoom level
        const scale = 2 ** mapZoom;
        const offset = isDesktop ? 400 / scale : 0;

        const spaceLeft = Math.abs(mapBounds[0] - lng) - offset;
        const spaceRight = Math.abs(mapBounds[2] - lng);

        return spaceLeft > spaceRight ? 'left' : 'right';
    };

    const decidePopupMobileOffset = () => {
        if (!mapBounds) return 50;

        const { lng } = marker.coordinates;

        const spaceLeft = Math.abs(mapBounds[0] - lng);
        const spaceRight = Math.abs(mapBounds[2] - lng);

        const spaceLeftPercentage = Math.abs(
            (spaceLeft * 100) / (spaceLeft + spaceRight),
        );

        // calculate the offset of the popup
        // relative to the center of the marker
        const offsetCenterUnclamped = 50 - spaceLeftPercentage;
        const offsetCenter = Math.min(
            Math.max(offsetCenterUnclamped, MIN_POPUP_OFFSET),
            MAX_POPUP_OFFSET,
        );

        return offsetCenter;
    };

    return (
        <div
            className={$.marker}
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
        >
            <button
                className={classNames(
                    $.circle,
                    marker.type === 'building' && $.primary,
                    marker.type === 'preferredSupplier' && $.yellow,
                    marker.type === 'contactPerson' && $.primary,
                )}
                onClick={openHandler}
                type="button"
            >
                <MarkerIcon icon={marker.icon} />
            </button>
            {popupVisible && (
                <PopupLocator
                    side={decidePopupSide()}
                    align={decidePopupAlign()}
                    mobileOffset={decidePopupMobileOffset()}
                    onClose={closeHandler}
                >
                    {marker.type === 'building' && (
                        <PopupLocatorLocation
                            address={marker.address}
                            link={marker.link}
                            title={marker.title}
                            topTitle={marker.topTitle}
                        />
                    )}
                    {marker.type === 'preferredSupplier' && (
                        <PopupLocatorPreferredSupplier
                            image={marker.image}
                            link={marker.link}
                            title={marker.title}
                            topTitle={marker.topTitle}
                        />
                    )}
                    {marker.type === 'contactPerson' && (
                        <PopupLocatorContact
                            email={marker.email}
                            image={marker.image}
                            phone={marker.phone}
                            title={marker.title}
                            topTitle={marker.topTitle}
                        />
                    )}
                </PopupLocator>
            )}
        </div>
    );
};

export default MapMarker;
