import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import classnames from 'classnames';

import { Title, ImagePin } from '~source/ui/components';
import ModuleWrapper from '~source/ui/components/modules/module-wrapper';
import FeaturedItem from './featured-item/featured-item';
import {
    Featured as FeaturedType,
    ImagePin as ImagePinModel,
    ModuleCard as ModuleCardModel,
} from '~source/core/models';
import $ from './featured.scss';

type Props = RouteComponentProps & FeaturedType;

const Featured: React.FunctionComponent<Props> = ({
    items,
    largeOnTop,
    subtitle,
    title,
}) => {
    const theme = largeOnTop ? undefined : 'wide';

    return (
        <ModuleWrapper theme={theme}>
            <>
                {title && (
                    <Title
                        className={$.title}
                        subtitle={subtitle}
                        title={title}
                    />
                )}
                <div className={classnames($.items, largeOnTop && $.itemsRow)}>
                    {items.map((item, index) => {
                        if (item.type === 'imagePin') {
                            const imagePin = item as ImagePinModel;
                            return (
                                <div
                                    key={`i-${imagePin.id}`}
                                    className={classnames(
                                        $.item,
                                        index === 0 && $.itemLarge,
                                    )}
                                >
                                    <ImagePin
                                        backgroundImage={
                                            imagePin.backgroundImage
                                        }
                                        id={imagePin.id}
                                        pins={imagePin.pins}
                                        title={imagePin.title}
                                        type={imagePin.type}
                                        withWrapper={false}
                                    />
                                </div>
                            );
                        }

                        if (item.type === 'card') {
                            const featuredItem = item as ModuleCardModel;
                            const { imageAlignment } = featuredItem;
                            return (
                                <div
                                    key={`f-${featuredItem.id}`}
                                    className={classnames(
                                        $.item,
                                        index === 0 && $.itemLarge,
                                        imageAlignment ===
                                            'bottomRightOverlap' &&
                                            $.featuredOverflow,
                                        largeOnTop && $.itemLargeOnTop,
                                    )}
                                >
                                    <FeaturedItem
                                        index={index}
                                        item={featuredItem}
                                        largeOnTop={largeOnTop}
                                    />
                                </div>
                            );
                        }

                        return null;
                    })}
                </div>
            </>
        </ModuleWrapper>
    );
};

export default withRouter(Featured);
