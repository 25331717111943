import * as React from 'react';
import { Button, ModuleCard, Image } from '~source/core/models';
import { Featured } from '~source/ui/components';
import useLabel from '~source/ui/hooks/use-label';
import $ from './page-not-found.scss';

const PageNotFound: React.FunctionComponent = () => {
    const title = useLabel('PageNotFound/Title');
    const subtitle = useLabel('PageNotFound/Subtitle');
    const text = useLabel('PageNotFound/Text');
    const buttonTitle = useLabel('PageNotFound/ButtonTitle');

    const image: Image = {
        id: '0',
        url: 'static/images/flower-broken.png',
    };

    const ctaButton: Button = {
        title: buttonTitle,
        url: '/',
    };

    const card: ModuleCard = {
        id: 'page-not-found',
        title,
        subtitle,
        image,
        text,
        imageAlignment: 'right',
        ctaButton,
    };

    const items: ModuleCard[] = [card];

    return (
        <div className={$.notFound}>
            <Featured
                id="page-not-found"
                items={items}
                type="featured"
                largeOnTop
            />
        </div>
    );
};

export default PageNotFound;
