import React from 'react';

import { Image } from '~source/ui/components';
import { TestimonialCard as TestimonialCardModel } from '~source/core/models';

import $ from './testimonial-card.scss';

type Props = {
    card: TestimonialCardModel;
};

const TestimonialCard: React.FC<Props> = ({ card }) => (
    <div className={$.container}>
        {card.image && (
            <Image
                alt={card.image.title}
                className={$.image}
                placeholder={`${card.image.url}?w=30&q=50`}
                src={`${card.image.url}?w=${420}&q=75`}
            />
        )}
        <div className={$.content}>
            <span className={$.quote}>{card.quote}</span>
            <span className={$.customer}>{card.customerName}</span>
        </div>
    </div>
);

export default TestimonialCard;
