import * as React from 'react';
import { Text as TextModel } from '~source/core/models';
import { HtmlText, Title, ModuleWrapper } from '~source/ui/components';
import $ from './text.scss';

const Text: React.FunctionComponent<TextModel> = (props: TextModel) => {
    const { body, topTitle, title } = props;

    return (
        <ModuleWrapper className={$.container}>
            <>
                {(title || topTitle) && (
                    <Title title={title} subtitle={topTitle} reverseTitles />
                )}

                <div className={$.textContainer}>
                    {body && <HtmlText className={$.body} content={body} />}
                </div>
            </>
        </ModuleWrapper>
    );
};

export default Text;
