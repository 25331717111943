import React from 'react';
import classNames from 'classnames';

import { LocatorMapType } from '~source/core/models';

import $ from './map-marker.scss';

type Props = {
    count: number;
    mapType: LocatorMapType;
    onClick: () => void;
    // lat and lng not being used in this component
    // but needed because GoogleMapReact uses it
    lat: number;
    lng: number;
};

const MapMarkerCluster: React.FC<Props> = ({ count, mapType, onClick }) => (
    <div
        className={classNames(
            $.circle,
            mapType === 'buildings' && $.primary,
            mapType === 'preferredSuppliers' && $.yellow,
            mapType === 'contactPersons' && $.primary,
        )}
        onClick={onClick}
    >
        {count}
    </div>
);

export default MapMarkerCluster;
