import React from 'react';
import classnames from 'classnames';
import { ModuleCard } from '~source/core/models';
import { Button, HtmlText, Title } from '~source/ui/components';
import Analytics, { tracking } from '~source/core/services/analytics';
import $ from './card.scss';

interface Props {
    size?: 'small' | 'medium' | 'large';
}

type CombinedProps = Props & ModuleCard;

const Card: React.FunctionComponent<CombinedProps> = ({
    color,
    ctaButton,
    size,
    subtitle,
    text,
    title,
}) => {
    const titleSize = size === 'large' ? 'medium' : size;
    const isSmall = size && size === 'small';
    const isMedium = size && size === 'medium';
    const cardStyle = classnames(
        $.card,
        isSmall && $.cardSmall,
        isMedium && $.cardMedium,
    );
    const buttonLabel = !isSmall ? ctaButton && ctaButton.title : undefined;

    const clickHandler = React.useCallback(() => {
        Analytics.event(tracking.events.cta, 'events', {
            category: tracking.categories.cta,
            action: (ctaButton && ctaButton.title) || '',
            label: subtitle || title,
        });
    }, [ctaButton, subtitle, title]);

    return (
        <div className={cardStyle}>
            {title && (
                <Title
                    title={title}
                    subtitle={subtitle}
                    size={titleSize}
                    color={color}
                />
            )}
            {text && <HtmlText content={text} />}
            {ctaButton && ctaButton.url && (
                <Button
                    className={$.button}
                    color="blue"
                    icon="chevron-right-white"
                    openInNewTab={ctaButton.openInNewTab}
                    onClick={clickHandler}
                    to={ctaButton.url}
                >
                    {buttonLabel}
                </Button>
            )}
        </div>
    );
};

export default Card;
