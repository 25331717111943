import { pick } from 'lodash';
import { Vacancies } from '../models';
import { isString } from '~source/utils/check-type';

export default function transformUspVacancies(data): Vacancies {
    if (!data) throw new Error('Vacancies module: no data');

    const id = data.id && isString(data.id);
    const title = data.title && isString(data.title);

    const items =
        data.vacancies &&
        data.vacancies.map(item => {
            return {
                title: item.title && item.title,
                subTitle: item.topTitle && item.topTitle,
                link: item.link && pick(item.link, ['url', 'title']),
                image:
                    item.foregroundImage &&
                    pick(item.foregroundImage, ['url', 'title']),
            };
        });

    return {
        type: 'vacancies',
        id,
        title,
        items,
    };
}
