import { Link } from '../models';
import { isString, isBool } from '~source/utils/check-type';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Entity = any;

export default function transformLink(data: Entity): Link {
    if (!data) throw new Error('Service: no data');

    const isActive = data.isActive && isBool(data.isActive);
    const openInNewTab = data.openInNewTab && isBool(data.openInNewTab);
    const title = data.title && isString(data.title);
    const url = data.url && isString(data.url);

    return {
        deepLinkId: data.deepLinkId,
        englishTitle: data.englishTitle,
        isActive,
        openInNewTab,
        title,
        url,
    };
}
