import * as React from 'react';

import { Init, Font } from '~source/core/models';
import setCssVar from '~source/utils/set-css-var';

const addedFilesCache = new Map<string, boolean>();

type Props = {
    variables: Record<string, string>;
    watch: boolean;
};

const cssVars = ({ variables, watch }: Props) => {
    if ('CSS' in window && CSS.supports('font-family', 'var(--fake-var)')) {
        Object.entries(variables).forEach(([key, value]) =>
            setCssVar({ key, value }),
        );
        return;
    }
    import('css-vars-ponyfill').then(module => {
        const cssVarsModule = module.default;
        cssVarsModule({ variables, watch });
    });
};

export default function useFonts(init: Init | null) {
    const loadFont = (font: Font) => {
        if (!font || !font.url) {
            return;
        }
        const { url } = font;

        if (addedFilesCache.get(url)) return;

        const head = document.head || document.getElementsByTagName('head')[0];
        const fileref = document.createElement('link');
        fileref.setAttribute('rel', 'stylesheet');
        fileref.setAttribute('type', 'text/css');
        fileref.setAttribute('href', url);
        head.appendChild(fileref);

        addedFilesCache.set(url, true);
    };

    const initFont = React.useCallback(async whiteLabel => {
        const {
            primaryFont,
            secondaryFont,
            useItalicsForTopTitle,
        } = whiteLabel;

        cssVars({
            variables: {
                'primary-font': (primaryFont && primaryFont.title) || '',
                'secondary-font': (secondaryFont && secondaryFont.title) || '',
                'font-style-title': useItalicsForTopTitle ? 'italic' : 'normal',
            },
            watch: true,
        });

        // Inject in <head>
        [primaryFont, secondaryFont].forEach(f => loadFont(f));
    }, []);

    React.useEffect(() => {
        if (init && init.whiteLabel) {
            initFont(init.whiteLabel);
        }
    }, [init, initFont]);
}
