import * as React from 'react';
import $ from './article-grid.scss';
import { ArticleGrid as ArticleGridType } from '~source/core/models';
import ModuleWrapper from '../module-wrapper';
import { Title } from '~source/ui/components';
import Button from '../../button';
import ArticleTeaser from '../../article-teaser';

type Props = ArticleGridType;

const ArticleGrid: React.FunctionComponent<Props> = (props: Props) => {
    const { items, title, subtitle, link } = props;
    // TODO: move data transformations to domain layer
    const leftItem = items.length > 0 && items.slice(0, 1);
    const rightItems = items.length > 1 && items.slice(1, 3);
    const rest = items.slice(3, items.length);

    return (
        <ModuleWrapper>
            <>
                {title && (
                    <Title
                        title={title}
                        subtitle={subtitle}
                        className={$.title}
                    />
                )}
                <section className={$.header}>
                    <section className={$.headerLeft}>
                        {leftItem && leftItem.length > 0 && (
                            <ArticleTeaser
                                layout="card"
                                {...leftItem[0]}
                                className={$.headerLeftArticle}
                                size="large"
                            />
                        )}
                    </section>
                    <section className={$.headerRight}>
                        {rightItems &&
                            rightItems.length > 0 &&
                            rightItems.map(item => (
                                <ArticleTeaser
                                    layout="card"
                                    {...item}
                                    className={$.headerRightArticle}
                                    key={item.title}
                                />
                            ))}
                    </section>
                </section>
                <section className={$.items}>
                    {rest.length > 0 &&
                        rest.map(item => {
                            return (
                                <ArticleTeaser
                                    layout="card"
                                    {...item}
                                    className={$.itemsArticle}
                                    key={item.title}
                                />
                            );
                        })}
                </section>
                {link && link.url && (
                    <Button
                        to={link.url}
                        className={$.link}
                        openInNewTab={link.openInNewTab}
                    >
                        {link.title}
                    </Button>
                )}
            </>
        </ModuleWrapper>
    );
};

export default ArticleGrid;
