import * as React from 'react';
import classnames from 'classnames';
import { MenuItem as MenuItemModel, Link } from '~source/core/models';
import $ from './menu-items.scss';
import MenuItem from '~source/ui/components/menu-item';

type Props = {
    className?: string;
    items: Link[];
    onClick: (i: MenuItemModel) => void;
    vertical?: boolean;
};

const MenuItems: React.FunctionComponent<Props> = ({
    className,
    items,
    onClick,
    vertical,
}: Props) => {
    if (!items) return null;

    return (
        <nav className={classnames(className)}>
            <ul
                className={classnames(
                    $.menuItems,
                    vertical && $.menuItemsVertical,
                )}
            >
                {items.map((item, index) => (
                    <MenuItem onClick={onClick} {...item} key={index} />
                ))}
            </ul>
        </nav>
    );
};

export default MenuItems;
