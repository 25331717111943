import React from 'react';
import classnames from 'classnames';
import $ from './contact-info.scss';
import { ContactInfo as ContactInfoType } from '~source/core/models';
import { Title, Profile, Address } from '~source/ui/components';
import useLabel from '~source/ui/hooks/use-label';

interface Props extends ContactInfoType {
    className?: string;
}

const ContactInfo: React.FunctionComponent<Props> = ({
    className,
    email,
    fax,
    header,
    person,
    phone,
    postAddress,
    title,
    visitAddress,
}: Props) => {
    const labels = {
        branch: useLabel('Contact/Branch'),
        branchManager: useLabel('Contact/BranchManager'),
        contactPerson: useLabel('Contact/ContactPerson'),
        postAddress: useLabel('Contact/Postaddress'),
        visitAddress: useLabel('Contact/Visitaddress'),
    };

    return (
        <section className={classnames($.wrap, className)}>
            <Title
                className={$.title}
                size="large"
                subtitle={header || labels.branch} // Using the original label as fallback
                title={title}
            />

            {person && (
                <Profile
                    {...person}
                    jobTitle={labels.branchManager}
                    title={labels.contactPerson}
                />
            )}

            {postAddress && (
                <Address address={postAddress} title={labels.postAddress} />
            )}
            {visitAddress && (
                <Address address={visitAddress} title={labels.visitAddress} />
            )}

            <section className={$.contact}>
                {phone && (
                    <p className={$.contactItem}>
                        <a
                            className={$.contactItemPhone}
                            href={`tel:${phone}`}
                            title={phone}
                        >
                            {phone}
                        </a>
                    </p>
                )}
                {fax && (
                    <p className={$.contactItem}>
                        <span className={$.contactItemFax}>{fax}</span>
                    </p>
                )}
                {email && (
                    <p className={$.contactItem}>
                        <a
                            className={$.contactItemEmail}
                            href={`mailto:${email}`}
                            title={email}
                        >
                            {email}
                        </a>
                    </p>
                )}
            </section>
        </section>
    );
};

export default ContactInfo;
