import { Alignment, CardLayout, Image, Link, StyledFont } from '.';
export class Card {
    backgroundColor?: string;
    backgroundImage?: Image;
    body?: string;
    foregroundImage?: Image;
    foregroundImageAlignment: Alignment;
    id: string;
    layout: CardLayout;
    lightText: boolean;
    link?: Link;
    promotionId?: string;
    showBackButton: boolean;
    showGradient: boolean;
    title?: string;
    titleFont?: StyledFont;
    topTitle?: string;
    type: string;

    constructor(data: any) {
        this.backgroundColor = data && data.backgroundColor;
        this.backgroundImage = data && data.backgroundImage;
        this.body = data && data.body;
        this.foregroundImage = data && data.foregroundImage;
        this.foregroundImageAlignment = data && data.foregroundImageAlignment;
        this.id = data.id;
        this.layout = data.layout;
        this.lightText = data.lightText;
        this.link = data && data.link;
        this.promotionId = data && data.promotionId;
        this.showBackButton = data.showBackButton;
        this.showGradient = data.showGradient;
        this.title = data && data.title;
        this.titleFont = data && data.titleFont;
        this.topTitle = data && data.title;
        this.type = data.type;
    }
}
