import * as React from 'react';
import classnames from 'classnames';
import { useLocation, useHistory } from 'react-router-dom';
import $ from './dropdown-menu.scss';
import { MenuItem } from '~source/core/models';
import { Link } from '~source/core/models';
import Analytics, { tracking } from '~source/core/services/analytics';

type Props = {
    links: Link[];
    isLight: boolean;
};

const DropdownMenu: React.FunctionComponent<Props> = props => {
    const { links, isLight } = props;
    const [value, setValue] = React.useState<string>('');
    const history = useHistory();
    const location = useLocation();

    const handleChange = React.useCallback(
        event => {
            const newLocation = event.target.value;
            setValue(newLocation);

            Analytics.event(tracking.events.pageMenu, 'events', {
                category: tracking.categories.menu,
                action: newLocation,
                label: '',
            });

            history.push(newLocation);
        },
        [history],
    );

    React.useEffect(() => {
        setValue(location.pathname);
    }, [location]);

    return (
        <div className={classnames($.dropdown, isLight && $.dropdownLight)}>
            <select
                value={value}
                onChange={event => handleChange(event)}
                className={$.select}
            >
                {links.map(link => (
                    <option key={link.url} value={link.url}>
                        {link.title}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default DropdownMenu;
