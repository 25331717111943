import * as React from 'react';
import { Tag } from '~source/core/models';
import Color from 'color';
import $ from './article-tag.scss';

type Props = {
    mainTag: Tag;
};

const ArticleTag: React.FunctionComponent<Props> = props => {
    const { mainTag } = props;
    if (!mainTag) return null;

    const { color: tagColor, title } = mainTag;
    const hslColor = tagColor && Color.hsl(tagColor);
    const isBlack = tagColor && tagColor === '#000000';
    const isDark = hslColor && hslColor.isDark();
    const backgroundColor = tagColor;
    const customColor = isDark ? hslColor.lighten(0.5) : hslColor.darken(0.6);
    const color = isBlack ? undefined : customColor;

    const tagStyle = tagColor
        ? {
              backgroundColor,
              color,
          }
        : undefined;

    return (
        <span className={$.tag} style={tagStyle}>
            {title}
        </span>
    );
};

export default ArticleTag;
