import * as React from 'react';
import Color from 'color';
import cssVarsModule from 'css-vars-ponyfill';
import { Init } from 'source/core/models';
import setCssVar from 'source/utils/set-css-var';

type Props = {
    variables: Record<string, string>;
    watch: boolean;
};

const cssVars = ({ variables, watch }: Props) => {
    if ('CSS' in window && CSS.supports('color', 'var(--fake-var)')) {
        Object.entries(variables).forEach(([key, value]) =>
            setCssVar({ key, value }),
        );
        return;
    }

    cssVarsModule({ variables, watch });
};

export default function useColors(init: Init | null) {
    React.useEffect(() => {
        let primaryBackgroundColor;
        let primaryFontColor;
        let primaryThemeColor;
        let primaryThemeColorLight;
        let secondaryFontColor;
        let secondaryThemeColor;
        let secondaryThemeColorLight;
        let tertiaryFontColor;
        if (init && init.whiteLabel) {
            const { whiteLabel } = init;
            ({
                primaryBackgroundColor,
                primaryFontColor,
                primaryThemeColor,
                primaryThemeColorLight,
                secondaryFontColor,
                secondaryThemeColor,
                secondaryThemeColorLight,
                tertiaryFontColor,
            } = whiteLabel);
        }

        const primaryThemeColorHsl = Color.hsl(primaryThemeColor);
        const primaryThemeColorLighter = primaryThemeColorHsl.lighten(0.2);
        const primaryThemeColorLightest = primaryThemeColorHsl.lighten(0.5);

        const secondaryThemeColorHsl = Color.hsl(secondaryThemeColor);
        const secondaryThemeColorLighter = secondaryThemeColorHsl.lighten(0.2);
        const secondaryThemeColorLightest = secondaryThemeColorHsl.lighten(0.5);

        cssVars({
            variables: {
                'primary-color-background': primaryBackgroundColor,
                'primary-color-font': primaryFontColor,
                'primary-color-theme': primaryThemeColor,
                'primary-color-theme-light': primaryThemeColorLight,
                'primary-color-theme-lighter': primaryThemeColorLighter,
                'primary-color-theme-lightest': primaryThemeColorLightest,
                'secondary-color-font': secondaryFontColor,
                'secondary-color-theme': secondaryThemeColor,
                'secondary-color-theme-light': secondaryThemeColorLight,
                'secondary-color-theme-lighter': secondaryThemeColorLighter,
                'secondary-color-theme-lightest': secondaryThemeColorLightest,
                'tertiary-color-font': tertiaryFontColor,
            },
            watch: true,
        });
    }, [init]);
}
