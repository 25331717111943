import * as React from 'react';
import classnames from 'classnames';
import { TextImage as TextImageModel } from '~source/core/models';
import {
    Button,
    HtmlText,
    Image,
    ModuleWrapper,
    Title,
} from '~source/ui/components';
import $ from './text-image.scss';
import breakpoints from '~source/core/constants/breakpoints';
import Analytics, { tracking } from '~source/core/services/analytics';

const TextImage: React.FunctionComponent<TextImageModel> = (
    props: TextImageModel,
) => {
    const {
        ctaButton,
        title,
        subtitle,
        text,
        text2,
        image,
        imageAlignment,
        backgroundColor,
        color,
        orientationReversed,
    } = props;

    const imageContainerStyle = classnames([
        $.imageContainer,
        orientationReversed && $.imageContainerReversed,
        (imageAlignment === 'top' ||
            imageAlignment === 'topRight' ||
            imageAlignment === 'topLeft') &&
            $.imageContainerTop,
        (imageAlignment === 'bottom' ||
            imageAlignment === 'bottomRight' ||
            imageAlignment === 'bottomLeft' ||
            imageAlignment === 'bottomRightOverlap') &&
            $.imageContainerBottom,
        (imageAlignment === 'right' ||
            imageAlignment === 'topRight' ||
            imageAlignment === 'bottomRight' ||
            imageAlignment === 'bottomRightOverlap') &&
            $.imageContainerRight,
        (imageAlignment === 'left' ||
            imageAlignment === 'topLeft' ||
            imageAlignment === 'bottomLeft') &&
            $.imageContainerLeft,
        imageAlignment === 'bottomRightOverlap' && $.imageContainerRightOverlap,
    ]);

    const imageWidth = window.innerWidth <= breakpoints.medium ? 550 : 850;

    const clickHandler = React.useCallback(() => {
        Analytics.event(tracking.events.cta, 'events', {
            category: tracking.categories.contact,
            action: (ctaButton && ctaButton.title) || '',
            label: subtitle || title,
        });
    }, [ctaButton, subtitle, title]);

    return (
        <ModuleWrapper
            className={$.container}
            style={{ backgroundColor, color }}
        >
            <article
                className={classnames(
                    $.flex,
                    orientationReversed && $.flexReversed,
                    image && image.url && $.hasImage,
                )}
            >
                <div className={$.article}>
                    {title && (
                        <div className={$.titleContainer}>
                            <Title title={title} subtitle={subtitle} />
                        </div>
                    )}

                    <div
                        className={classnames(
                            $.textContainer,
                            text2 && $.textContainerSplit,
                        )}
                    >
                        {text && <HtmlText content={text} />}
                        {text2 && <HtmlText content={text2} />}
                    </div>

                    {ctaButton && ctaButton.title && ctaButton.url && (
                        <div className={$.buttonContainer}>
                            <Button
                                className={$.button}
                                color="blue"
                                textLight
                                icon="chevron-right-white"
                                onClick={clickHandler}
                                to={ctaButton.url}
                                openInNewTab={ctaButton.openInNewTab}
                            >
                                {ctaButton.title}
                            </Button>
                        </div>
                    )}
                </div>

                {image && image.url && (
                    <div className={imageContainerStyle}>
                        <div>
                            {/* placeholder because of image alignment */}
                            {image && image.url && (
                                <Image
                                    src={`${image.url}?w=${imageWidth}&q=75`}
                                    alt={image.title}
                                    className={$.imagePlaceholder}
                                />
                            )}
                            {/* actual shown image */}
                            {image && image.url && (
                                <Image
                                    placeholder={`${image.url}?w=30&q=50`}
                                    src={`${image.url}?w=${imageWidth}&q=75`}
                                    alt={image.title}
                                    className={$.image}
                                    // isBackground
                                />
                            )}
                        </div>
                    </div>
                )}
            </article>
        </ModuleWrapper>
    );
};

export default TextImage;
