import * as React from 'react';
import { debounce } from 'lodash';

const getWindowWidth = () => window.innerWidth;

export default function useWindowWidth() {
    const [windowWidth, setWindowWidth] = React.useState(getWindowWidth);

    React.useEffect(() => {
        const handler = debounce(() => {
            setWindowWidth(getWindowWidth());
        }, 0);
        window.addEventListener('resize', handler, { passive: true });
        return () => {
            window.removeEventListener('resize', handler);
        };
    }, []);

    return windowWidth;
}
