import React from 'react';

// The useInView hook checks if an element has been scrolled into view
// If element is below viewport it is set to false (default)
// If element is in viewport or above it it is set to true

type offsetHeight =
    | 0.5
    | 0.6
    | 0.7
    | 0.8
    | 0.9
    | 1
    | 1.1
    | 1.2
    | 1.3
    | 1.4
    | 1.5
    | 1.6
    | 1.7
    | 1.8
    | 1.9
    | 2;

const useInView = (
    trigger: React.MutableRefObject<HTMLDivElement>,
    offsetHeight: offsetHeight,
) => {
    const [inView, setInView] = React.useState(false);

    const checkInView = () => {
        const screenPos = window.screen.availHeight * offsetHeight;
        const triggerPos =
            trigger &&
            trigger.current &&
            trigger.current.getBoundingClientRect().top;

        if (screenPos >= triggerPos) {
            setInView(true);
        } else {
            setInView(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener('scroll', checkInView);

        return () => {
            document.removeEventListener('scroll', checkInView);
        };
    }, []);

    return inView;
};

export default useInView;
