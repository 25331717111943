import { TextImageCta } from '~source/core/models';
import { transformCard } from '~source/core/transforms';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Entity = any;

export default function transformTextImageCta(data: Entity): TextImageCta {
    if (!data) throw new Error('TextImageCta module: no data');

    const type = 'text-image-cta';
    const {
        backgroundColor,
        backgroundImage,
        color,
        ctaButton,
        id,
        image,
        imageAlignment,
        layout,
        showBackButton,
        subtitle,
        text,
        title,
        titleFont,
    } = transformCard(data);

    const showGradient = data.showGradient ? data.showGradient : false;

    return {
        backgroundColor,
        backgroundImage,
        color,
        ctaButton,
        id,
        image,
        imageAlignment,
        layout,
        showBackButton,
        showGradient,
        subtitle,
        text,
        title,
        titleFont,
        type,
    };
}
