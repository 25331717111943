import React from 'react';
import classnames from 'classnames';
import { Container } from '../..';
import { ModuleContext } from '~source/ui/context';
import $ from './module-wrapper.scss';

type Theme = 'slim' | 'medium' | 'wide';

interface Props {
    children: React.ReactChild | React.ReactChildren | React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
    theme?: Theme;
}

const ModuleWrapper = (props: Props) => {
    const { children, className, style, ...rest } = props;
    const wrapStyles = classnames([$.wrap, className]);
    const { useContainer } = React.useContext(ModuleContext);
    return (
        <section className={wrapStyles} style={style}>
            {useContainer ? (
                <Container {...rest}>{children}</Container>
            ) : (
                children
            )}
        </section>
    );
};

export default ModuleWrapper;
