import * as React from 'react';
import { InitContext } from '~source/ui/context';

export default function useLabel(key: string) {
    const [label, setLabel] = React.useState('');
    const init = React.useContext(InitContext);

    React.useEffect(() => {
        if (init && init.labels) {
            const { labels } = init;
            setLabel(labels[`S/${key}`] || '');
        }
    }, [init, key]);

    return label;
}
