import React from 'react';
import cx from 'classnames';

import { Container, Image, Link, Tag } from '..';
import { Header } from '~source/core/models';
import { useLabel, useWindowWidth } from '~source/ui/hooks';
import Analytics, { tracking } from '~source/core/services/analytics';
import breakpoints from '~source/core/constants/breakpoints';
import $ from './page-header.scss';

const PageHeader: React.FC<Header> = ({
    hasLightTheme,
    image,
    linkLeft,
    linkRight,
    subtitle,
    tag,
    title,
}) => {
    const onClickAnalyticsHandler = React.useCallback(
        (englishTitle: string) => {
            const { host, pathname, hash } = window.location;
            Analytics.event(tracking.events.redirect, 'events', {
                category: tracking.categories.inspirationRedirect,
                action: englishTitle || '',
                label: `${title} | ${subtitle} | ${host}${pathname}${hash}`,
            });
        },
        [title, subtitle],
    );

    const labels = {
        package: useLabel('Inspiration/BackToLookbook'),
        webshop: useLabel('General/Webshop'),
    };
    const windowWidth = useWindowWidth();

    if (!image || !image.url) return null;

    const imageWidth = windowWidth <= breakpoints.medium ? 550 : 1600;

    return (
        <section
            className={cx($.pageHeader, {
                [$.light as string]: hasLightTheme,
            })}
        >
            <Image
                placeholder={`${image.url}?w=30&q=50`}
                src={`${image.url}?w=${imageWidth}&q=75`}
                alt={image.title}
                isBackground
            />
            <div className={$.overlay} />
            <Container theme="slim" className={$.container}>
                <nav className={$.nav}>
                    {linkLeft && (
                        <Link
                            className={cx($.link, $.linkLeft)}
                            to={linkLeft.url}
                            onClick={() =>
                                onClickAnalyticsHandler(linkLeft.englishTitle)
                            }
                        >
                            {labels.package}
                        </Link>
                    )}
                    {linkRight && (
                        <Link
                            className={cx($.link, $.linkRight)}
                            to={linkRight.url}
                            target="_blank"
                            onClick={() =>
                                onClickAnalyticsHandler(linkRight.englishTitle)
                            }
                        >
                            {labels.webshop}
                        </Link>
                    )}
                </nav>
                <div className={$.text}>
                    {/* Do not use heading tags, these are defined in <Article /> for instance */}
                    {tag && <Tag>{tag}</Tag>}
                    {subtitle && <p className={$.subtitle}>{subtitle}</p>}
                    <p className={$.title}>{title}</p>
                </div>
            </Container>
        </section>
    );
};

export default PageHeader;
