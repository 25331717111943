import React from 'react';

import { Button } from '~source/ui/components';
import { Image, Link } from '~source/core/models';

import $ from './popup-locator.scss';

type Props = {
    image?: Image;
    link?: Link;
    title: string;
    topTitle: string;
};

const PopupLocatorPreferredSupplier: React.FC<Props> = ({
    image,
    link,
    title,
    topTitle,
}) => (
    <div className={$.preferredSupplier}>
        {image && (
            <div
                style={{ backgroundImage: `url(${image.url})` }}
                className={$.image}
            />
        )}
        <div className={$.details}>
            <span className={$.topTitle}>{topTitle}</span>
            <span className={$.title}>{title}</span>
            {link && (
                <Button
                    className={$.button}
                    color="blue"
                    icon="chevron-right-white"
                    openInNewTab={link.openInNewTab}
                    to={link.url}
                >
                    {link.title}
                </Button>
            )}
        </div>
    </div>
);

export default PopupLocatorPreferredSupplier;
