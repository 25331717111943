import { ImagePin } from 'source/core/models';

const transformImagePin = (data: any): ImagePin => {
    if (!data) throw new Error('Image pin module: no data.');

    return {
        backgroundImage: data.image,
        id: data.id,
        pins: data.pins || [],
        title: data.title || '',
        type: 'imagePin',
    };
};

export default transformImagePin;
