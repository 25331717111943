import { InspirationPackage } from '~source/core/models';
import { transformLink } from '~source/core/transforms';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Entity = any;

function transformSubset(data: Entity) {
    return {
        id: data.id,
        image: data.image,
        lookbookLink: data.lookbookLink && transformLink(data.lookbookLink),
        productsLink: data.productsLink && transformLink(data.productsLink),
        title: data.title,
        topTitle: data.topTitle,
    };
}

export default function transformInspirationPackage(
    data: Entity,
): InspirationPackage {
    return {
        analyticsSubtitle: data.analyticsSubtitle,
        analyticsTitle: data.analyticsTitle,
        id: data.id,
        image: data.image,
        lookbookLink: data.lookbookLink && transformLink(data.lookbookLink),
        productsLink: data.productsLink && transformLink(data.productsLink),
        subsets: (data.subsets && data.subsets.map(transformSubset)) || [],
        timeLeft: data.timeLeft,
        title: data.title,
        subtitle: data.topTitle || data.subtitle,
        type: 'inspirationPackage',
    };
}
