import React from 'react';
import Popover from 'react-popover';
import Cookies from 'js-cookie';

import { InitContext } from '~source/ui/context';
import { Button, Image } from '~source/ui/components';
import useLabel from '~source/ui/hooks/use-label';

import $ from './webshop-button.scss';

interface Props {
    showPopover?: boolean;
}

const WebshopButton: React.FC<Props> = ({ showPopover = false }) => {
    const init = React.useContext(InitContext);
    const [mountPopover, setMountPopover] = React.useState<boolean>(false);
    const label = useLabel('LoginForm/Description');

    if (!init) return null;

    const { myFmPopupCard, shopUrl } = init;

    const onCloseHandler = () => {
        localStorage.setItem('webshop-button-popup', 'closed');
        setMountPopover(false);
    };

    const PopoverBody: React.FC = () => {
        if (!myFmPopupCard) return null;

        const { body, title, foregroundImage } = myFmPopupCard;

        return (
            <div className={$.popupCard}>
                <div>
                    <h3 className={$.title}>{title}</h3>
                    {body && (
                        <p
                            className={$.text}
                            dangerouslySetInnerHTML={{ __html: body }}
                        />
                    )}
                </div>
                <div>
                    {foregroundImage && foregroundImage.url && (
                        <Image
                            src={foregroundImage.url}
                            placeholder={foregroundImage.url}
                            className={$.image}
                        />
                    )}
                </div>
                <button className={$.close} onClick={onCloseHandler} />
            </div>
        );
    };

    React.useEffect(() => {
        const cookies = Cookies.get('fleurametz-accept-cookies');
        const allowedByCookie = cookies && cookies.indexOf('basic') !== -1;
        const popupClosed =
            localStorage.getItem('webshop-button-popup') === 'closed';

        if (
            !init ||
            !init.myFmPopupCard ||
            !showPopover ||
            !allowedByCookie ||
            popupClosed
        )
            return;
        setMountPopover(true);
    }, [init, init.myFmPopupCard, showPopover]);

    return (
        <Popover
            body={<PopoverBody />}
            className={$.popover}
            isOpen={mountPopover}
            place="below"
            tipSize={9}
        >
            <Button iconFirst textLight color="red" to={shopUrl} icon="profile">
                {label}
            </Button>
        </Popover>
    );
};

export default WebshopButton;
