import React from 'react';
import { Services as ServicesModel } from '~source/core/models';
import {
    ModuleHandler,
    ModuleWrapper,
    ServiceItem,
    Title,
} from '~source/ui/components';
import { transformModules } from '~source/core/transforms';
import $ from './services-list.scss';

const ServicesList: React.FunctionComponent<ServicesModel> = ({
    title,
    topTitle,
    body,
    items,
    label,
    modules,
}: ServicesModel) => {
    const subModules = transformModules(modules);

    return (
        <ModuleWrapper>
            <section className={$.servicesList}>
                <Title
                    title={topTitle}
                    subtitle={title}
                    className={$.servicesTopTitle}
                    size="large"
                />
                {body && <p className={$.servicesListToptext}>{body}</p>}
                {label && <p className={$.servicesListLabel}>{label}</p>}
                {items &&
                    items.map(item => (
                        <ServiceItem {...item} layout="list" key={item.title} />
                    ))}
                {subModules && <ModuleHandler modules={subModules} />}
            </section>
        </ModuleWrapper>
    );
};

export default ServicesList;
