import * as React from 'react';
import { Container } from '~source/ui/components';

// Render a <Container> with n lines.
export const containerWithMockLines = (count: number) => {
    const a: number[] = [];
    for (let i = 0; i < count; i += 1) {
        a.push(i + 1);
    }
    const line = (s: string) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>| content {s}</div>
            <div>|</div>
        </div>
    );
    return (
        <Container style={{ backgroundColor: 'beige' }}>
            <>
                {line('start')}
                {a.map(i => (
                    <div key={i}>{i}</div>
                ))}
                {line('end')}
            </>
        </Container>
    );
};

export const getRandomString = () => {
    return Math.random()
        .toString()
        .split('.')[1];
};
