import * as React from 'react';
import useWindowWidth from './use-window-width';

function createBreakpointConfig(breakPoints: number[] = []) {
    const [small, medium, large] = breakPoints;
    return { small, medium, large };
}

const breakpointConfigs = {
    1440: createBreakpointConfig([768, 1024, 1440]),
    1024: createBreakpointConfig([480, 800, 1140]),
    768: createBreakpointConfig([300, 768, 1024]),
    480: createBreakpointConfig([768, 768, 768]),
    0: createBreakpointConfig([480, 480, 480]),
};

const breakPoints = Object.keys(breakpointConfigs).map(breakPoint =>
    Number(breakPoint),
);

function getMaxBreakpoint(windowWidth: number) {
    return Math.max(
        ...breakPoints.filter(breakPoint => windowWidth > breakPoint),
    );
}

export default function useImageSize() {
    const windowWidth = useWindowWidth();
    const [breakPoint, setBreakPoint] = React.useState<number>(
        getMaxBreakpoint(windowWidth),
    );

    React.useEffect(() => {
        setBreakPoint(getMaxBreakpoint(windowWidth));
    }, [windowWidth]);

    return breakpointConfigs[breakPoint];
}
