import * as React from 'react';
import { Helmet } from 'react-helmet';
import { InitContext } from '../context';

function createTitle(title: string, subTitle?: string, cultureId?: string) {
    const culture = cultureId ? `(${cultureId})` : '';
    let result = `${title} ${culture}`;
    if (subTitle) {
        result = `${subTitle} — ${title} ${culture}`;
    }
    return result;
}

type Props = {
    subTitle?: string;
    seoDescription?: string;
    seoKeywords?: string;
};

export default function Head(props: Props) {
    const init = React.useContext(InitContext);
    if (!init) return null;
    const {
        cultures,
        facebookDomainVerificationId,
        isNotFound,
        whiteLabel,
    } = init;

    let title;
    let favicon;
    if (whiteLabel) {
        ({ siteTitle: title, favicon } = whiteLabel);
    }
    const { subTitle, seoDescription, seoKeywords } = props;
    const currentCulture = cultures.find(c => !!c.isActive);
    const cultureId = currentCulture && currentCulture.id;

    return (
        <Helmet>
            {title && <title>{createTitle(title, subTitle, cultureId)}</title>}
            {title && (
                <meta
                    property="og:title"
                    content={createTitle(title, subTitle, cultureId)}
                />
            )}
            {favicon && favicon.url && (
                <link rel="icon" href={favicon.url} type="image/x-icon" />
            )}
            {seoDescription && (
                <meta name="description" content={seoDescription} />
            )}
            {seoDescription && (
                <meta property="og:description" content={seoDescription} />
            )}
            {seoKeywords && <meta name="keywords" content={seoKeywords} />}
            {facebookDomainVerificationId && (
                <meta
                    name="facebook-domain-verification"
                    content={facebookDomainVerificationId}
                />
            )}
            {<meta property="og:url" content={window.location.href} />}
            {<meta property="og:type" content="website" />}
            {isNotFound && <meta name="prerender-status-code" content="404" />}
        </Helmet>
    );
}
