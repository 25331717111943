import React from 'react';
import classnames from 'classnames';
import Cookies from 'js-cookie';
import useLabel from '~source/ui/hooks/use-label';
import useFormValue from '~source/ui/hooks/use-form-value';
import {
    hasDigit,
    hasLetter,
    isEmail,
    isPassword,
    isRequired,
} from '~source/utils/form-validation';
import { Country } from '~source/core/models';
import {
    Button,
    Checkbox,
    HtmlText,
    Input,
    Title,
} from '~source/ui/components';
import { InitContext, ConfigContext } from '~source/ui/context';
import $ from './register-form.scss';

export interface FormData {
    city: string;
    clientId: string;
    companyName: string;
    countryId: string;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    repeatPassword: string;
    sessionId: string | undefined;
    signUpForWebshopCode: boolean;
    streetAndHouseNumber: string;
    terms: boolean;
    zipCode: string;
}

export interface RegisterFormProps {
    body?: string;
    countries?: Country[];
    error: boolean;
    onSubmit: (data: FormData) => void;
    orderCode: boolean;
    setError: (error: boolean) => void;
    setFormData?: (value: FormData | null) => void;
    setOrderCode: (orderCode: boolean) => void;
    subtitle?: string;
    success: boolean;
    title?: string;
}

const RegisterForm: React.FC<RegisterFormProps> = ({
    body,
    countries,
    error,
    onSubmit,
    orderCode,
    setError,
    setFormData,
    setOrderCode,
    subtitle,
    success,
    title,
}) => {
    const init = React.useContext(InitContext);
    const firstName = useFormValue('', hasLetter);
    const lastName = useFormValue('', hasLetter);
    const companyName = useFormValue('', isRequired);
    const streetAndHouseNumber = useFormValue('', hasLetter);
    const { initialCultureId } = React.useContext(ConfigContext);
    const countryId = useFormValue(initialCultureId, hasLetter);
    const zipCode = useFormValue('', hasDigit);
    const city = useFormValue('', hasLetter);
    const phoneNumber = useFormValue('', hasDigit);
    const email = useFormValue('', isEmail);
    const password = useFormValue('', isPassword);
    const repeatPassword = useFormValue('', isPassword);
    const [termsIsChecked, setTermsIsChecked] = React.useState(false);
    const [formValid, setFormValid] = React.useState(false);
    const wrapRef = React.useRef<HTMLElement>(null);
    const [wrapHeight, setWrapHeight] = React.useState<number>();
    const ga = Cookies.get('_ga'); // gets the Google Analytics _ga from cookie (can be undefined)
    const clientId = (ga && ga.slice(6)) || 'unknown';
    const sessionId = Cookies.get('fm_session');

    /** Only the "fleurametz" whitelabel has the OPTION to request a webshop code. The other whitelabels all automatically request one. */
    const isFleurametzWhiteLabel =
        (init && init.whiteLabel.id === 'fleurametz') || false;

    const labels = {
        city: useLabel('Registration/Form/City'),
        companyName: useLabel('Registration/Form/CompanyName'),
        country: useLabel('Registration/Form/Country'),
        email: useLabel('Registration/Form/Email'),
        firstName: useLabel('Registration/Form/FirstName'),
        invalidEmail: useLabel('Form/Contact/InvalidEmail'),
        lastName: useLabel('Registration/Form/LastName'),
        orderCodeInfo: useLabel('Registration/Form/OrderCodeInfo'),
        password: useLabel('Registration/Form/Password'),
        passwordNoMatch: useLabel('Password/Rules/NoMatch'),
        passwordRules: useLabel('Password/Rules'),
        phoneNumber: useLabel('RegisterForm/PhoneNumber'),
        phoneNumberRequired: useLabel('RegisterForm/Error/PhoneNumberRequired'),
        register: useLabel('Registration/Form/Register'),
        repeatPassword: useLabel('Registration/Form/RepeatPassword'),
        signupOrderCode: useLabel('Registration/Form/SubmitOrderCode'),
        signupOrderCodeInfo: useLabel('Registration/Form/OrderCodeInfo'),
        streetAndHouseNumber: useLabel(
            'Registration/Form/StreetAndHouseNumber',
        ),
        successText: useLabel('Registration/Form/SuccessMessage/Text'),
        successTitle: useLabel('Registration/Form/SuccessMessage/Title'),
        termsAndConditions: useLabel('Registration/Form/TermsAndConditions'),
        usernameExists: useLabel('RegisterForm/Error/UsernameExists'),
        zipCode: useLabel('Registration/Form/ZipCode'),
    };

    React.useEffect(() => {
        const isValid =
            city.valid &&
            companyName.valid &&
            countryId.valid &&
            phoneNumber.valid &&
            email.valid &&
            firstName.valid &&
            lastName.valid &&
            password.valid &&
            password.value === repeatPassword.value &&
            repeatPassword.valid &&
            streetAndHouseNumber.valid &&
            termsIsChecked &&
            zipCode.valid;
        setFormValid(isValid);
    }, [
        city,
        companyName,
        countryId,
        email,
        phoneNumber,
        firstName,
        lastName,
        password,
        repeatPassword,
        streetAndHouseNumber,
        termsIsChecked,
        zipCode,
    ]);

    /** Set the orderCode to true automatically when the whitelabel is not "fleurametz". */
    React.useEffect(() => {
        if (!isFleurametzWhiteLabel) {
            setOrderCode(true);
        }
    });

    const termsOnChange = React.useCallback(() => {
        setTermsIsChecked(!termsIsChecked);
    }, [termsIsChecked]);

    const orderCodeOnChange = React.useCallback(() => {
        /** Disable possibility of setting orderCode boolean if it's not the "fleurametz" whitelabel. */
        if (!isFleurametzWhiteLabel) return;

        setOrderCode(!orderCode);
    }, [isFleurametzWhiteLabel, orderCode, setOrderCode]);

    const submitHandler = React.useCallback(
        e => {
            e.preventDefault();

            const formData = {
                city: city.value,
                clientId,
                companyName: companyName.value,
                countryId: countryId.value,
                phoneNumber: phoneNumber.value,
                email: email.value,
                firstName: firstName.value,
                lastName: lastName.value,
                password: password.value,
                repeatPassword: repeatPassword.value,
                sessionId,
                signUpForWebshopCode: isFleurametzWhiteLabel ? orderCode : true,
                streetAndHouseNumber: streetAndHouseNumber.value,
                terms: termsIsChecked,
                zipCode: zipCode.value,
            };

            if (setFormData && formData) {
                setFormData(formData);
            }

            onSubmit(formData);
        },
        [
            city.value,
            clientId,
            companyName.value,
            countryId.value,
            phoneNumber.value,
            email.value,
            firstName.value,
            lastName.value,
            password.value,
            repeatPassword.value,
            sessionId,
            isFleurametzWhiteLabel,
            orderCode,
            streetAndHouseNumber.value,
            termsIsChecked,
            zipCode.value,
            setFormData,
            onSubmit,
        ],
    );

    React.useEffect(() => {
        if (wrapRef && wrapRef.current) {
            setWrapHeight(wrapRef.current.clientHeight);

            if (success) {
                wrapRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        }
    }, [wrapRef, success]);

    if (!init) return null;

    return (
        <section
            className={$.wrap}
            ref={wrapRef}
            style={{ height: `${wrapHeight}px` || 'auto' }}
        >
            {!success && (
                <>
                    <Title
                        title={title}
                        subtitle={subtitle}
                        className={$.headTitle}
                        size="large"
                    />
                    <HtmlText className={$.headBody} content={body} />
                    <form onSubmit={submitHandler}>
                        <div className={classnames($.flex, $.row)}>
                            <Input
                                onChange={firstName.onChange}
                                placeholder={labels.firstName}
                                required
                                valid={firstName.valid}
                                value={firstName.value}
                            />
                            <Input
                                onChange={lastName.onChange}
                                placeholder={labels.lastName}
                                required
                                valid={lastName.valid}
                                value={lastName.value}
                            />
                        </div>

                        <Input
                            onChange={companyName.onChange}
                            placeholder={labels.companyName}
                            required
                            valid={companyName.valid}
                            value={companyName.value}
                        />

                        <div className={classnames($.flex, $.row)}>
                            <Input
                                onChange={streetAndHouseNumber.onChange}
                                placeholder={labels.streetAndHouseNumber}
                                required
                                valid={streetAndHouseNumber.valid}
                                value={streetAndHouseNumber.value}
                            />
                            <Input
                                onChange={countryId.onChange}
                                placeholder={labels.country}
                                required
                                type="select"
                                valid={countryId.valid}
                                value={countryId.value}
                                values={
                                    countries &&
                                    countries.map(val => ({
                                        value: val.id,
                                        title: val.title,
                                    }))
                                }
                            />
                        </div>
                        <div className={classnames($.flex, $.row)}>
                            <Input
                                onChange={zipCode.onChange}
                                placeholder={labels.zipCode}
                                required
                                valid={zipCode.valid}
                                value={zipCode.value}
                            />
                            <Input
                                onChange={city.onChange}
                                placeholder={labels.city}
                                required
                                valid={city.valid}
                                value={city.value}
                            />
                        </div>
                        <Input
                            errorMessage={labels.phoneNumberRequired}
                            onChange={phoneNumber.onChange}
                            placeholder={labels.phoneNumber}
                            required
                            valid={phoneNumber.valid}
                            value={phoneNumber.value}
                        />
                        <Input
                            errorMessage={
                                error
                                    ? labels.usernameExists
                                    : labels.invalidEmail
                            }
                            onChange={e => {
                                email.onChange(e);
                                setError(false);
                            }}
                            placeholder={labels.email}
                            required
                            valid={email.valid && !error}
                            value={email.value}
                        />
                        <div className={$.marginVertical}>
                            <Input
                                errorMessage={labels.passwordRules}
                                hint
                                hintText={labels.passwordRules}
                                onChange={password.onChange}
                                placeholder={labels.password}
                                required
                                type="password"
                                valid={password.valid}
                                value={password.value}
                            />
                            <Input
                                errorMessage={labels.passwordNoMatch}
                                onChange={repeatPassword.onChange}
                                placeholder={labels.repeatPassword}
                                required
                                type="password"
                                valid={password.value === repeatPassword.value}
                                value={repeatPassword.value}
                            />
                        </div>

                        {/* Don't show the checkbox if it's not the "fleurametz" whitelabel. Other whitelabels request the orderCode by default. */}
                        {isFleurametzWhiteLabel && (
                            <div className={$.flex}>
                                <Checkbox
                                    checked={orderCode}
                                    onChange={orderCodeOnChange}
                                    styledAsInput
                                    title={labels.signupOrderCode}
                                >
                                    <span className={$.signupTitle}>
                                        {labels.signupOrderCode}
                                    </span>
                                    <p className={$.signupInfo}>
                                        {labels.signupOrderCodeInfo}
                                    </p>
                                </Checkbox>
                            </div>
                        )}

                        <div className={$.flex}>
                            <Checkbox
                                checked={termsIsChecked}
                                className={$.checkbox}
                                onChange={termsOnChange}
                                title={labels.termsAndConditions}
                            >
                                <span>
                                    <HtmlText
                                        content={labels.termsAndConditions}
                                    />
                                </span>
                            </Checkbox>

                            <Button
                                disabled={formValid === false}
                                type="submit"
                            >
                                {labels.register}
                            </Button>
                        </div>
                    </form>
                </>
            )}

            {success && (
                <div className={$.success}>
                    <Title
                        subtitle={labels.successTitle}
                        className={$.successTitleWrapper}
                    />
                    <p>{labels.successText}</p>
                </div>
            )}
        </section>
    );
};

export default RegisterForm;
