import { ImageModel } from '../models';
import { Image } from '~source/core/models';

export default function transformImage(data): ImageModel {
    if (!data) throw new Error('Image module: no data');
    const { id, images } = data;
    if (!id || !images || !images.length)
        throw new Error('Image module: no images');
    const filtered = images.filter((i: Image) => i && i.url && i.title);

    return {
        type: 'image',
        id,
        images: filtered,
    };
}
