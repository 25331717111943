export function isEmail(value: string) {
    const matched = value.match(
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

    return matched !== null;
}

export function isRequired(value: string, minLength = 1) {
    return value.length >= minLength;
}

export function hasDigit(value: string) {
    if (!isRequired(value)) {
        return false;
    }
    return /[0-9]/g.test(value);
}

export function hasLetter(value: string) {
    if (!isRequired(value)) {
        return false;
    }
    return /[a-zA-ZÀ-ÿ]/g.test(value);
}

export function hasDigitAndLetter(value: string) {
    if (!isRequired(value)) {
        return false;
    }
    return /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-ZÀ-ÿ0-9\s]+)$/.test(value);
}

export function isPassword(password: string): boolean {
    const minLength = 7;
    const minNonAlphabeticCharacters = 1;

    // eslint-disable-next-line no-useless-escape
    const minNonAlphabeticCharactersRegex = new RegExp(/[^\a-zA-Z]/g);

    // check for min length and amount of special characters
    return (
        typeof password === 'string' &&
        password.length >= minLength &&
        (password.match(minNonAlphabeticCharactersRegex) || []).length >=
            minNonAlphabeticCharacters
    );
}
