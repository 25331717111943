import React from 'react';
import classnames from 'classnames';
import { Link } from '~source/ui/components';
import { Image } from '~source/core/models';
import { InitContext } from '~source/ui/context';
import $ from './logo.scss';

type Props = {
    logoLarge: Image;
    logoSmall: Image;
    mobile: boolean;
    size: 'medium' | 'small';
};

const Logo: React.FunctionComponent<Props> = ({
    logoLarge,
    logoSmall,
    mobile,
    size,
}) => {
    const init = React.useContext(InitContext);

    return (
        <Link
            to={(init && init.siteUrl) || '/'}
            className={classnames(
                $.logoWrapper,
                size === 'small' && $.logoWrapperSmall,
            )}
        >
            <img
                className={classnames(
                    $.logoIcon,
                    size === 'small' && $.logoIconSmall,
                    mobile && $.logoIconMobile,
                )}
                src={logoSmall.url}
                alt={logoSmall.title}
            />
            <img
                className={classnames(
                    $.logoFull,
                    size === 'small' && $.logoFullSmall,
                    mobile && $.logoFullMobile,
                )}
                src={logoLarge.url}
                alt={logoLarge.title}
            />
        </Link>
    );
};

export default Logo;
