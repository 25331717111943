/* eslint-disable @typescript-eslint/no-explicit-any */
import fetchUrl from '~source/core/services/fetch-url';

export default async function submitForm(
    formId: string,
    endpoint: string,
    data: any,
    mustReturnFullResponse?: boolean,
) {
    const result = await fetchUrl<any>(
        {
            method: 'POST',
            endpoint,
            body: data,
            params: { formId },
        },
        mustReturnFullResponse,
    );

    return result;
}
