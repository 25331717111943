import * as React from 'react';
import LazyImage from 'react-lazy-progressive-image';
import classnames from 'classnames';
import $ from './image.scss';

interface Props {
    src: string | null;
    placeholder?: string | null;
    isBackground?: boolean;
    alt?: string;
    style?: React.CSSProperties;
    className?: string;
}

const Image = (props: Props) => {
    const {
        placeholder,
        src: dataSrc,
        isBackground,
        alt,
        style,
        className,
    } = props;
    const blur = 65;

    return (
        <LazyImage
            placeholder={placeholder}
            src={dataSrc}
            visibilitySensorProps={{
                partialVisibility: true,
                offset: { top: 10 },
                minTopValue: 0,
            }}
        >
            {(src, loading) => {
                if (isBackground) {
                    return (
                        <>
                            <div
                                className={classnames(
                                    $.backgroundImage,
                                    className,
                                )}
                                style={{
                                    ...style,
                                    backgroundImage: `url("${src}")`,
                                    filter: loading
                                        ? `blur(${blur}px)`
                                        : 'none',
                                    // Hardware accelerate to paint on separate layer and force blur pixels to be animated
                                    transform: 'translateZ(0)',
                                }}
                            />
                            {src && (
                                <noscript>
                                    <img src={src} alt={alt} />
                                </noscript>
                            )}
                        </>
                    );
                }

                return (
                    <img
                        src={src}
                        className={className}
                        alt={alt}
                        style={{
                            ...style,
                            filter: loading ? `blur(${blur}px)` : 'none',
                            // Hardware accelerate to paint on separate layer and force blur pixels to be animated
                            transform: 'translateZ(0)',
                        }}
                    />
                );
            }}
        </LazyImage>
    );
};

export default Image;
