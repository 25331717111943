import React from 'react';
import fetchUrl from '~source/core/services/fetch-url';
import transformArticleTeaser from '~source/core/transforms/transformArticleTeaser';
import { ArticleTeaser } from '~source/core/models/article-teaser';

const useArticleService = (
    items: ArticleTeaser[],
    next: string | undefined,
) => {
    const [articles, setArticles] = React.useState<ArticleTeaser[]>(items);
    const [articleError, setArticleError] = React.useState<boolean>(false);
    const [running, setRunning] = React.useState<boolean>(false);
    const [endpoint, setEndpoint] = React.useState<string | undefined>(next);

    const fetchArticles = () => {
        if (!endpoint || running) return;

        setRunning(true);

        fetchUrl({ endpoint })
            .then((data: any) => {
                setEndpoint(data.next);
                const newArticles = data.articleThumbnails.map(article =>
                    transformArticleTeaser(article),
                );
                setArticles([...articles, ...newArticles]);
            })
            .catch(error => {
                setArticleError(true);
            })
            .finally(() => {
                setRunning(false);
            });
    };

    return { articles, articleError, fetchArticles };
};

export default useArticleService;
