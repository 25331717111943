import React from 'react';
import $ from './submenu-chevron.scss';

const SubmenuChevron = () => {
    const svg = {
        __html: `<svg xmlns="http://www.w3.org/2000/svg" width="9" height="10" viewBox="0 0 16 10">
      		<path fill="#404040" fill-rule="evenodd" d="M13.44.44a1.5 1.5 0 012.12 2.12l-6.5 6.5a1.5 1.5 0 01-2.12 0l-6.5-6.5A1.5 1.5 0 012.56.44L8 5.878l5.44-5.44z"/>
    	</svg>`,
    };

    return <div dangerouslySetInnerHTML={svg} className={$.submenuChevron} />;
};

export default SubmenuChevron;
