import * as React from 'react';
import classnames from 'classnames';
import $ from './address.scss';
import HtmlText from '../html-text';

interface Props {
    title: string;
    address: string;
    className?: string;
}

const Address: React.FunctionComponent<Props> = ({
    title,
    address,
    className,
}: Props) => {
    return (
        <address className={classnames($.address, className && className)}>
            <span className={$.addressTitle}>{title}:</span>
            <br />
            <HtmlText content={address} />
        </address>
    );
};

export default Address;
