import endpoints from '~source/core/constants/endpoints';
import fetchUrl from '~source/core/services/fetch-url';
import { ConfigContextType } from '~source/ui/context/config-context';
import checkResponse from '~source/utils/check-response';
import transformInit from '../transforms/transformInit';

export default async function fetchInit(config: ConfigContextType) {
    const endpoint = config.apiUrl + endpoints.init;

    const params = {
        referrer: window.location.href,
        browserCulture: navigator.language || navigator.userLanguage,
        culture: config.cultureId || undefined,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response = await fetchUrl<any>({
        endpoint,
        params,
    });

    // check for most important property of init response because API doesn't
    // return a proper error state if it fails (yet)
    if (
        checkResponse(['siteInitResponse'], response) &&
        response.headerMenu &&
        response.headerMenu.length
    ) {
        return transformInit(response);
    }

    if (response && response.type === 'redirectResponse') {
        return { isRedirect: true };
    }

    return null;
}
