type SetCssVar = {
    element?: HTMLElement;
    key: string;
    value: string;
};

export default function setCssVar({
    element = document.documentElement,
    key,
    value,
}: SetCssVar) {
    element.style.setProperty(`--${key}`, value);
}
