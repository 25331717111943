import { transformInspirationPackage } from '.';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function transformOtherPackagesCarousel(data: any) {
    return {
        id: data.id,
        packages:
            (data.packages && data.packages.map(transformInspirationPackage)) ||
            [],
        title: data.title,
        topTitle: data.topTitle,
        type: data.type,
    };
}
