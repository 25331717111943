import React from 'react';
import { Form as FormModel } from '~source/core/models';
import { ModuleWrapper } from '~source/ui/components';
import {
    ContactForm,
    RegisterForm,
    LoginForm,
} from '~source/ui/components/modules';
import { FormData as RegisterFormData } from '~source/ui/components/modules/form/register-form/register-form';
import submitForm from '~source/core/services/submit-form';
import Analytics, { tracking } from '~source/core/services/analytics';
import $ from './form.scss';

const Form: React.FunctionComponent<FormModel> = ({
    backgroundImage,
    body,
    countries,
    formType,
    id,
    postUrl,
    subtitle,
    title,
}) => {
    const [error, setError] = React.useState<boolean>(false);
    const [success, setSuccess] = React.useState<boolean>(false);
    const formDataRef = React.useRef<RegisterFormData | null>(null);
    const [orderCode, setOrderCode] = React.useState<boolean>(false);
    const setFormData = (value: RegisterFormData | null) => {
        formDataRef.current = value;
    };

    const getCategory = React.useCallback(() => {
        switch (formType) {
            case 'contact':
                return {
                    event: tracking.events.contactForm,
                    category: tracking.categories.contact,
                    action: 'contact form submit',
                };
            case 'registration':
            case 'registrationv2':
            case 'registrationeventflorist':
            case 'registrationstoreownerflorist':
                return {
                    event: tracking.events.accountRequest,
                    category: tracking.categories.account,
                    action: 'account request',
                    label: orderCode
                        ? 'webshop code included'
                        : 'webshop code not included',
                };
            default:
                return {
                    event: formType,
                    category: formType,
                    action: `${formType} form submit`,
                };
        }
    }, [formType, orderCode]);

    const trackPost = React.useCallback(() => {
        const { event, category, action, label: eventLabel } = getCategory();
        Analytics.event(event, 'events', {
            category,
            action,
            label: eventLabel || '',
        });
    }, [getCategory]);

    const formSubmit = React.useCallback(
        async data => {
            const response = await submitForm(id, postUrl, data, true);

            if (response.status === 200) {
                trackPost();
                setSuccess(true);
            } else {
                // This could be a non-200 error for the login form
                // or a 596 (existing user) for the register form
                setError(true);
            }
            return false;
        },
        [id, postUrl, trackPost],
    );

    return (
        <section>
            <ModuleWrapper className={$.container}>
                {formType === 'contact' && (
                    <ContactForm
                        body={body}
                        onSubmit={formSubmit}
                        setSuccess={setSuccess}
                        subtitle={subtitle}
                        success={success}
                        title={title}
                    />
                )}
                {(formType === 'registration' ||
                    formType === 'registrationv2' ||
                    formType === 'registrationeventflorist' ||
                    formType === 'registrationstoreownerflorist') && (
                    <RegisterForm
                        body={body}
                        countries={countries}
                        error={error}
                        onSubmit={formSubmit}
                        orderCode={orderCode}
                        setError={setError}
                        setFormData={setFormData}
                        setOrderCode={setOrderCode}
                        subtitle={subtitle}
                        success={success}
                        title={title}
                    />
                )}
                {formType === 'login' && (
                    <LoginForm
                        backgroundImage={backgroundImage}
                        body={body}
                        error={error}
                        onSubmit={formSubmit}
                        subtitle={subtitle}
                        success={success}
                        title={title}
                    />
                )}
            </ModuleWrapper>
        </section>
    );
};

export default Form;
