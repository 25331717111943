import { Html } from '../models';

export default function transformHtml(data: any): Html {
    if (!data) throw new Error('Html module: no data');

    const { body, id } = data;

    return {
        content: body,
        id,
        type: 'html',
    };
}
