import * as React from 'react';
import classnames from 'classnames';
import $ from './page-navigation.scss';
import MenuItems from '../menu-items';
import { MenuItem } from '~source/core/models';
import { Link } from '~source/core/models';
import useWindowWidth from '~source/ui/hooks/use-window-width';
import breakpoints from '~source/core/constants/breakpoints';
import Analytics, { tracking } from '~source/core/services/analytics';
import DropdownMenu from '../dropdown-menu';

type Props = {
    links: MenuItem[] | Link[];
    type: 'horizontal' | 'vertical';
};

const PageNavigation: React.FunctionComponent<Props> = props => {
    const { links, type } = props;
    const windowWidth = useWindowWidth();
    const isHorizontal = type === 'horizontal';
    const isMobile =
        windowWidth < (isHorizontal ? breakpoints.medium : breakpoints.large);
    const clickHandler = React.useCallback(i => {
        Analytics.event(tracking.events.pageMenu, 'events', {
            category: tracking.categories.menu,
            action: i.title,
            label: '',
        });
    }, []);

    return (
        <>
            {isMobile ? (
                <DropdownMenu links={links} isLight={isHorizontal} />
            ) : (
                <MenuItems
                    items={links}
                    className={classnames(
                        $.pageNavigation,
                        isHorizontal && $.pageNavigationHorizontal,
                    )}
                    onClick={clickHandler}
                    vertical={!isHorizontal}
                />
            )}
        </>
    );
};

export default PageNavigation;
