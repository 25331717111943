import { ArticleGrid } from '../models';
import { isString } from '~source/utils/check-type';
import { transformArticleTeaser, transformLink } from '~source/core/transforms';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Entity = any;

export default function transformArticleGrid(data: Entity): ArticleGrid {
    if (!data) throw new Error('Services module: no data');

    const id = data.id && isString(data.id);
    const title = data.title && isString(data.title);
    const subtitle = data.topTitle && isString(data.topTitle);
    const link = data.link && transformLink(data.link);

    return {
        type: 'articleGrid',
        id,
        title,
        subtitle,
        link,
        items: data.articlePages.map(item => transformArticleTeaser(item)),
    };
}
