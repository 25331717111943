/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, react/jsx-wrap-multilines */
import React from 'react';
import Popover from 'react-popover';

import { ImagePin as ImagePinModel, Pin as PinModel } from 'source/core/models';
import { Button, Image, ModuleWrapper } from 'source/ui/components';
import { ModuleContext } from 'source/ui/context';
import { useWindowWidth, useLabel } from 'source/ui/hooks';
import Analytics, { tracking } from 'source/core/services/analytics';
import breakpoints from 'source/core/constants/breakpoints';

import $ from './image-pin.scss';

const Pin: React.FC<PinModel> = ({
    deepLink,
    imageUrl,
    isMultiVariantDeepLink,
    moduleTitle,
    posX,
    posY,
    subsetId,
    title,
}) => {
    const labels = {
        articles: useLabel('MultipleArticles'),
        button: useLabel('ImagePin/Pin/Button'),
        webshop: useLabel('ViewInWebshop'),
    };

    const [popover, setPopover] = React.useState(false);
    let closePopoverTimeout: number;
    let openPopoverTimeout: number;

    const openPopoverHandler = () => {
        /** Open the popover with a 100ms delay. If the popover wasn't opened, trigger the analytics view event. */
        openPopoverTimeout = window.setTimeout(() => {
            if (!popover) {
                Analytics.event(tracking.events.pinView, 'events', {
                    category: tracking.categories.inspirationPinView,
                    action: labels.button,
                    label: `${moduleTitle} | ${subsetId} | ${title}`,
                });
            }

            setPopover(true);
        }, 100);

        /** Cancel any running closePopoverHandler calls. This happens when the popover is still open because of the trigger on the icon, but is also going to be closed by closePopoverHandler, which is triggered by mousing out of the icon element.
         *
         * Effectively, this gives the user a 500 ms timeframe to re-hover one of the elements that opens the popover, to keep it opened. */
        window.clearTimeout(closePopoverTimeout);
    };

    const closePopoverHandler = () => {
        /** If there is a popover opening timer running, cancel it. */
        window.clearTimeout(openPopoverTimeout);

        closePopoverTimeout = window.setTimeout(() => {
            setPopover(false);
        }, 500);
    };

    /** Trigger an analytics event when the button inside the popover is clicked. */
    const onClickHandler = React.useCallback(() => {
        Analytics.event(tracking.events.pinClick, 'events', {
            category: tracking.categories.inspirationPinClick,
            action: `${labels.button} | ${imageUrl}`,
            label: `${moduleTitle} | ${subsetId} | ${title}`,
        });
    }, [deepLink, imageUrl, title]);

    return (
        <Popover
            body={
                <div
                    className={$.flex}
                    onMouseEnter={openPopoverHandler}
                    onMouseLeave={closePopoverHandler}
                >
                    {/* If the deeplink only contains 1 article. */}
                    {!isMultiVariantDeepLink && (
                        <>
                            <div className={$.left}>
                                <div
                                    className={$.image}
                                    style={{
                                        backgroundImage: `url(${imageUrl})`,
                                    }}
                                />
                            </div>
                            <div className={$.right}>
                                <div>
                                    <p className={$.title}>{title}</p>
                                    <p className={$.subtitle}>
                                        {labels.webshop}
                                    </p>
                                </div>
                                <Button
                                    icon="chevron-right-white"
                                    onClick={onClickHandler}
                                    openInNewTab
                                    to={deepLink.url}
                                >
                                    {labels.button}
                                </Button>
                            </div>
                        </>
                    )}

                    {/* If the deeplink contains multiple articles. */}
                    {isMultiVariantDeepLink && (
                        <div className={$.col}>
                            <p className={$.title}>{labels.articles}</p>
                            <p className={$.subtitle}>{labels.webshop}</p>
                            <Button
                                className={$.marginTop}
                                icon="chevron-right-white"
                                onClick={onClickHandler}
                                openInNewTab
                                to={deepLink.url}
                            >
                                {labels.button}
                            </Button>
                        </div>
                    )}
                </div>
            }
            className={$.pinPopover}
            isOpen={popover}
            tipSize={9}
        >
            <div
                className={$.pin}
                onMouseEnter={openPopoverHandler}
                onMouseLeave={closePopoverHandler}
                style={{
                    position: 'absolute',
                    top: `${posY}%`,
                    left: `${posX}%`,
                }}
            >
                <div className={$.icon} />
            </div>
        </Popover>
    );
};

interface ImagePinProps {
    withWrapper?: boolean;
}

const ImagePin: React.FC<ImagePinModel & ImagePinProps> = ({
    backgroundImage,
    pins: pinsFromProps,
    title,
    withWrapper = true,
}) => {
    const pins = pinsFromProps.map((pin) => new PinModel(pin));

    // useContainer = true = module 100% width, else 50% width
    const { useContainer } = React.useContext(ModuleContext);
    const windowWidth = useWindowWidth();
    const isTablet =
        windowWidth >= breakpoints.small && windowWidth < breakpoints.large;
    const isMobile = windowWidth < breakpoints.small;

    return withWrapper ? (
        <ModuleWrapper className={$.container}>
            <div className={$.root}>
                <Image
                    alt={backgroundImage.title}
                    placeholder={`${backgroundImage.url}?w=30&q=50`}
                    src={`${backgroundImage.url}?w=${
                        // Module covers 100% width and is not on a small screen use 750px width
                        (useContainer && !isMobile) ||
                        // Module covers 50% width and is on a tablet screen use 750px width
                        (!useContainer && isTablet)
                            ? 750
                            : 450
                    }&q=75`}
                />
                {pins.map((pin: PinModel) => (
                    <Pin {...pin} key={pin.id} />
                ))}
            </div>
        </ModuleWrapper>
    ) : (
        <div className={$.root}>
            <Image
                alt={backgroundImage.title}
                placeholder={`${backgroundImage.url}?w=30&q=50`}
                src={`${backgroundImage.url}?w=${
                    // Module covers 100% width and is not on a small screen use 750px width
                    (useContainer && !isMobile) ||
                    // Module covers 50% width and is on a tablet screen use 750px width
                    (!useContainer && isTablet)
                        ? 750
                        : 450
                }&q=75`}
            />
            {pins.map((pin) => (
                <Pin {...pin} key={pin.id} moduleTitle={title} />
            ))}
        </div>
    );
};

export default ImagePin;
