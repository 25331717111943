import {
    transformArticleTeaser,
    transformHeader,
    transformModules,
    transformOtherPackagesCarousel,
} from '../transforms';
import Page from '../models/page';
import pageTypes, { pageLabels } from '../constants/pageTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Entity = any;

function getPageType(type) {
    switch (type) {
        case pageTypes.withSidebar:
            return pageLabels.withSidebar;
        case pageTypes.article:
            return pageLabels.article;
        case pageTypes.category:
            return pageLabels.category;
        case pageTypes.full:
        default:
            return 'full';
    }
}

export default function transformPage(data: Entity): Page {
    if (!data) throw new Error('Page: no data');

    const {
        analyticsId,
        id,
        next,
        pageType,
        seoDescription,
        seoKeywords,
        title,
        url,
    } = data;
    const type = getPageType(data.type);
    const sidebarLinks = data.sidebarLinks && data.sidebarLinks;
    const header = data.header && transformHeader(data.header);
    const headerImage = data.headerImage && data.headerImage;
    const isLargeHeaderImage =
        data.isLargeHeaderImage && data.isLargeHeaderImage;
    const articleTags = data.articleTags && data.articleTags;
    const publishTime = data.publishTime || '';
    const author = data.author && data.author;
    const otherPackagesCarousel =
        data.otherPackagesCarousel &&
        transformOtherPackagesCarousel(data.otherPackagesCarousel);
    const introduction = data.introduction && data.introduction;
    const featuredItems =
        data.articleThumbnails &&
        data.articleThumbnails
            .slice(0, 3)
            .map(item => transformArticleTeaser(item));
    const items =
        data.articleThumbnails &&
        data.articleThumbnails
            .slice(3)
            .map(item => transformArticleTeaser(item));
    const relatedItems =
        data.relatedArticles &&
        data.relatedArticles.map(item => transformArticleTeaser(item));
    const topTitle = data.topTitle && data.topTitle;
    const tabs = data.tabs && data.tabs;
    const modules = data.modules && transformModules(data.modules);

    return new Page({
        analyticsId,
        articleTags,
        author,
        featuredItems,
        header,
        headerImage,
        id,
        introduction,
        isLargeHeaderImage,
        items,
        modules,
        next,
        otherPackagesCarousel,
        pageType,
        publishTime,
        relatedItems,
        seoDescription,
        seoKeywords,
        sidebarLinks,
        tabs,
        title,
        topTitle,
        type,
        url,
    });
}
