import * as React from 'react';
import YouTube from 'react-youtube';
import { Video as VideoModel } from '~source/core/models';
import ModuleWrapper from '../module-wrapper';
import $ from './video.scss';

const Video: React.FunctionComponent<VideoModel> = (props: VideoModel) => {
    const { videoId } = props;

    return (
        <ModuleWrapper theme="medium">
            <div className={$.videoWrapper}>
                <YouTube
                    id={videoId}
                    videoId={videoId}
                    containerClassName={$.videoContainer}
                    className={$.iframe}
                />
            </div>
        </ModuleWrapper>
    );
};

export default Video;
