import React from 'react';
import classnames from 'classnames';
import { InitContext } from '~source/ui/context';
import {
    Container,
    HamburgerMenu,
    HeaderMenuItem,
    HeaderTopMenu,
    Icon,
    Logo,
    Submenu,
    WebshopButton,
} from '~source/ui/components';
import $ from './header.scss';
import { useWindowWidth, useScrollPosition } from '~source/ui/hooks';
import breakpoints from '~source/core/constants/breakpoints';
import Analytics, { tracking } from '~source/core/services/analytics';

const Header: React.FunctionComponent = () => {
    const init = React.useContext(InitContext);
    const windowWidth = useWindowWidth();
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const [isSmall, setSmall] = React.useState<boolean>(false);
    const [showSubmenu, setShowSubmenu] = React.useState<boolean>(false);
    const [submenuTrigger, setSubmenuTrigger] = React.useState<string>('');
    const [visibleSubmenuItems, setVisibleSubmenuItems] = React.useState([]);

    const toggleExpanded = React.useCallback(
        (bool?: boolean) => {
            if (typeof bool === 'boolean') {
                setExpanded(bool);
            } else {
                setExpanded(!expanded);
            }
        },
        [expanded],
    );

    const clickHandler = React.useCallback(item => {
        Analytics.event(tracking.events.menu, 'events', {
            category: tracking.categories.menu,
            action: item.title,
            label: '',
        });
    }, []);

    React.useEffect(() => {
        const el = document.querySelector('html');
        if (!el) return;
        if (expanded) {
            el.style.position = 'fixed';
        } else {
            el.style.position = 'inherit';
        }
    }, [expanded]);

    useScrollPosition(
        ({ currPos }) => setSmall(currPos.y < -30),
        [],
        null,
        null,
        null,
    );

    if (!init) return null;

    const { headerMenu, whiteLabel } = init;
    const isMobile = windowWidth < breakpoints.large;

    return (
        <>
            <header className={classnames($.header, isSmall && $.headerSmall)}>
                <Container className={$.container}>
                    <div className={$.content}>
                        {isMobile && (
                            <button
                                type="button"
                                className={$.hamburgerButton}
                                onClick={() => toggleExpanded()}
                            >
                                <Icon className={$.hamburgerIcon} />
                            </button>
                        )}
                        <Logo
                            size={isSmall ? 'small' : 'medium'}
                            mobile={isMobile}
                            logoLarge={whiteLabel.logoLarge}
                            logoSmall={whiteLabel.logoSmall}
                        />
                        {!isMobile && (
                            <nav>
                                {headerMenu.map((item, index) => (
                                    <HeaderMenuItem
                                        {...item}
                                        key={index}
                                        setShowSubmenu={setShowSubmenu}
                                        setVisibleSubmenuItems={
                                            setVisibleSubmenuItems
                                        }
                                        onClick={clickHandler}
                                        setSubmenuTrigger={setSubmenuTrigger}
                                        showSubmenu={showSubmenu}
                                        submenuTrigger={submenuTrigger}
                                    />
                                ))}
                            </nav>
                        )}

                        {!isMobile && init && init.shopUrl && (
                            <WebshopButton showPopover />
                        )}
                        {!isMobile && !init.shopUrl && (
                            <div style={{ minWidth: '8rem' }} />
                        )}
                        {!isMobile && <HeaderTopMenu headerIsSmall={isSmall} />}
                    </div>
                </Container>
                <Submenu
                    isSmall={isSmall}
                    setShowSubmenu={setShowSubmenu}
                    visible={showSubmenu}
                >
                    {visibleSubmenuItems}
                </Submenu>
                {!isMobile && <div className={$.backgroundFill} />}
            </header>
            <HamburgerMenu
                expanded={expanded}
                toggleExpanded={toggleExpanded}
            />
        </>
    );
};

export default Header;
