import React from 'react';
import { Service } from '~source/core/models';
import { Button, Link, HtmlText } from '~source/ui/components';
import useLabel from '~source/ui/hooks/use-label';
import $ from './service-item.scss';

const ServiceItem = ({
    title: itemTitle,
    body: itemBody,
    image,
    link,
    layout,
}: Service) => {
    const readMore = useLabel('ReadMore');

    if (layout === 'list' && link && link.url) {
        return (
            <Link
                to={link.url}
                target={link.openInNewTab ? '_blank' : '_self'}
                className={$.listItemLink}
            >
                <div className={$.listItem}>
                    <img
                        className={$.listItemImg}
                        src={image.url}
                        alt={itemTitle}
                    />
                    <div className={$.listItemTitle}>{itemTitle}</div>
                </div>
            </Link>
        );
    }

    if (layout === 'list' && !link) {
        return (
            <div className={$.listItem}>
                <img
                    className={$.listItemImg}
                    src={image.url}
                    alt={itemTitle}
                />
                <div className={$.listItemTitle}>{itemTitle}</div>
            </div>
        );
    }

    return (
        <div className={$.item} key={itemTitle}>
            <div className={$.itemContent}>
                <div className={$.itemContentLeft}>
                    <img
                        className={$.itemContentImg}
                        src={image.url}
                        alt={itemTitle}
                    />
                </div>

                <div className={$.itemContentRight}>
                    <h3 className={$.itemContentTitle}>{itemTitle}</h3>
                    {itemBody && (
                        <HtmlText
                            content={itemBody}
                            className={$.itemContentBody}
                        />
                    )}
                    {link && link.url && (
                        <>
                            <Button
                                className={$.itemContentButton}
                                color="blue"
                                to={link.url}
                                openInNewTab={link.openInNewTab}
                            >
                                {link.title || readMore}
                            </Button>
                            <Link to={link.url} className={$.itemContentLink}>
                                <img
                                    className={$.itemContentLinkIcon}
                                    src="/static/icons/chevron-right-gray.svg"
                                    alt=">"
                                />
                            </Link>
                        </>
                    )}
                </div>
                {link && link.url && (
                    <a
                        className={$.linkOverlay}
                        href={link.url}
                        target={link.openInNewTab ? '_blank' : '_self'}
                    />
                )}
            </div>
        </div>
    );
};

export default ServiceItem;
