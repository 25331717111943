import { Video } from '../models';
import { isString } from '~source/utils/check-type';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Entity = any;

export default function transformVideo(data: Entity): Video {
    if (!data) throw new Error('Video module: no data');

    const type = 'video';
    const id = isString(data.id);
    const title = data.title && isString(data.title);
    const videoId = isString(data.videoIdentifier);

    return {
        type,
        id,
        title,
        videoId,
    };
}
