import React from 'react';
import { Helmet } from 'react-helmet';
import { Html as HtmlModel } from '~source/core/models';
import { HtmlText } from '~source/ui/components';
import $ from './html.scss';

const Html: React.FunctionComponent<HtmlModel> = ({ content }) => {
    const scripts = content.match(/<script.*<\/script>/g);
    const sources =
        scripts && scripts.map(script => script.match(/src="(.*?)"/));

    return (
        <>
            <HtmlText
                className={$.wrap}
                content={content}
                allowTags={['iframe', 'div']}
            />
            {sources &&
                sources.map((source: RegExpMatchArray, index: number) => (
                    <Helmet key={index}>
                        <script src={`${source[1]}`} />
                    </Helmet>
                ))}
        </>
    );
};

export default Html;
