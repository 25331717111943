import React from 'react';
import classnames from 'classnames';

import { Icon, TestimonialCard, Title } from '~source/ui/components';
import { makeid } from '~source/utils/make-id';
import { Testimonial as TestimonialModel } from '~source/core/models';
import { useSnappedIndex, useWindowWidth } from '~source/ui/hooks';
import Analytics, { tracking } from '~source/core/services/analytics';
import breakpoints from '~source/core/constants/breakpoints';

import $ from './testimonial.scss';

const Testimonial: React.FC<TestimonialModel> = ({
    cards,
    title,
    subtitle,
}) => {
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < breakpoints.medium;
    const isTablet =
        windowWidth >= breakpoints.small && windowWidth < breakpoints.large;

    const getGroupSize = () => {
        if (isMobile) return 1;
        if (isTablet) return 2;
        return 3;
    };
    const groupSize = getGroupSize();
    const groupCount = Math.ceil(cards.length / groupSize);

    const [activeGroupIndex, setActiveGroupIndex] = React.useState(0);

    const scrollRef = React.useRef<HTMLDivElement>(null);
    const { snapToIndex } = useSnappedIndex(scrollRef, (index: number) =>
        setActiveGroupIndex(index / groupSize),
    );

    React.useEffect(() => {
        const startIndex = activeGroupIndex * groupSize;
        const endIndex = startIndex + groupSize;
        const visibleCards = cards.filter(
            (_, index) => index >= startIndex && index < endIndex,
        );
        Analytics.event(tracking.events.promoView, 'events', {
            category: tracking.categories.ecommerce,
            action: 'testimonial view',
            genericItem: visibleCards
                .map(({ quote, customerName, image }) => {
                    let item = `${quote} | ${customerName}`;
                    if (image) item += ` | ${image.url}`;
                    return item;
                })
                .join(' | '),
            creative: 'carrousel',
            position: activeGroupIndex + 1,
        });
    }, [cards, activeGroupIndex, groupSize]);

    const canPrevious = activeGroupIndex > 0;
    const canNext = activeGroupIndex < groupCount - 1;

    const previousClickHandler = () => {
        if (!canPrevious) return;
        setActiveGroupIndex(activeGroupIndex - 1);
    };

    const nextClickHandler = () => {
        if (!canNext) return;
        setActiveGroupIndex(activeGroupIndex + 1);
    };

    const indicatorClickHandler = (groupIndex: number) => {
        if (groupIndex < 0 || groupIndex > groupCount - 1) return;
        setActiveGroupIndex(groupIndex);

        if (isMobile) snapToIndex(groupIndex * groupSize);
    };

    const cardsInActiveGroup = cards.slice(
        activeGroupIndex * groupSize,
        activeGroupIndex * groupSize + groupSize,
    );
    const baseOffset = activeGroupIndex * groupSize * (100 / groupSize) - 100;
    const activeOffset = cardsInActiveGroup.length * (100 / groupSize);
    const offset = baseOffset + activeOffset;

    const hasOverflow = cards.length > groupSize;

    return (
        <div className={$.container}>
            <Title className={$.title} subtitle={subtitle} title={title} />
            <div className={$.slider}>
                {hasOverflow && (
                    <button
                        type="button"
                        className={$.pager}
                        onClick={previousClickHandler}
                        disabled={!canPrevious}
                    >
                        <Icon className={$.chevronLeft} size="small" />
                    </button>
                )}
                <div className={$.inner}>
                    <div className={$.content}>
                        <div
                            className={$.cards}
                            style={{
                                transform: isMobile
                                    ? undefined
                                    : `translate(-${offset}%)`,
                            }}
                            ref={scrollRef}
                        >
                            {cards.map(card => (
                                <div
                                    key={card.id}
                                    className={classnames([
                                        $.card,
                                        groupSize === 1 && $.small,
                                        groupSize === 2 && $.medium,
                                        groupSize === 3 && $.large,
                                    ])}
                                >
                                    <TestimonialCard card={card} />
                                </div>
                            ))}
                        </div>
                    </div>
                    {hasOverflow && (
                        <div className={$.indicators}>
                            {[...Array(groupCount)].map((_, groupIndex) => (
                                <button
                                    key={makeid(5)}
                                    type="button"
                                    className={classnames([
                                        $.indicator,
                                        groupIndex === activeGroupIndex &&
                                            $.active,
                                    ])}
                                    onClick={() =>
                                        indicatorClickHandler(groupIndex)
                                    }
                                />
                            ))}
                        </div>
                    )}
                </div>
                {hasOverflow && (
                    <button
                        type="button"
                        className={$.pager}
                        onClick={nextClickHandler}
                        disabled={!canNext}
                    >
                        <Icon className={$.chevronRight} size="small" />
                    </button>
                )}
            </div>
        </div>
    );
};

export default Testimonial;
