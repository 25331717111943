import React from 'react';
import classNames from 'classnames';

import { Button, MarkerIcon } from '~source/ui/components';
import { Legend, LocatorMapType } from '~source/core/models';
import { useWindowWidth } from '~source/ui/hooks';
import breakpoints from '~source/core/constants/breakpoints';

import $ from './locator-legend.scss';

type Props = {
    legend: Legend;
    mapType: LocatorMapType;
};

const LocatorLegend: React.FC<Props> = ({ legend, mapType }) => {
    const windowWidth = useWindowWidth();
    const isDesktop = windowWidth >= breakpoints.large;

    return (
        <div className={$.wrapper}>
            <div className={$.legend}>
                <span className={$.topTitle}>{legend.topTitle}</span>
                <span className={$.title}>{legend.title}</span>
                {isDesktop && (
                    <div className={$.items}>
                        {legend.items &&
                            legend.items.length > 1 &&
                            legend.items.map(item => (
                                <div className={$.item} key={item.id}>
                                    <div
                                        className={classNames(
                                            $.circle,
                                            mapType === 'buildings' &&
                                                $.primary,
                                            mapType === 'preferredSuppliers' &&
                                                $.yellow,
                                            mapType === 'contactPersons' &&
                                                $.primary,
                                        )}
                                    >
                                        <MarkerIcon icon={item} />
                                    </div>
                                    <span className={$.itemLabel}>
                                        {item.title}
                                    </span>
                                </div>
                            ))}
                    </div>
                )}
                {legend.link && (
                    <div className={$.cta}>
                        <Button
                            color="blue"
                            icon="chevron-right-white"
                            openInNewTab={legend.link.openInNewTab}
                            to={legend.link.url}
                        >
                            {legend.link.title}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LocatorLegend;
