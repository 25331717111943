import * as React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import $ from './opening-hours.scss';
import useLabel from '~source/ui/hooks/use-label';
import { OpeningHours as OpeningHourType } from '~source/core/models';
import { Title } from '~source/ui/components';

type Props = {
    dates: OpeningHourType[];
    className?: string;
};

const OpeningHours: React.FunctionComponent<Props> = ({
    dates,
    className,
}: Props) => {
    const currentDay = moment().isoWeekday();
    const labels = {
        openingHours: useLabel('Contact/OpeningHours'),
    };

    return (
        <section className={classnames($.openingHours, className)}>
            <section className={$.openingHoursInner}>
                <Title
                    className={$.openingHoursTitle}
                    title={labels.openingHours}
                />
                {dates &&
                    dates.length > 0 &&
                    dates.map((item, i) => {
                        return (
                            <div
                                key={item.day}
                                className={classnames(
                                    $.openingHoursDate,
                                    currentDay === i + 1 &&
                                        $.openingHoursDateActive,
                                )}
                            >
                                <div className={$.openingHoursDateLeft}>
                                    {item.day}
                                </div>
                                <div className={$.openingHoursDateRight}>
                                    {item.time}
                                </div>
                            </div>
                        );
                    })}
            </section>
        </section>
    );
};

export default OpeningHours;
