/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import classnames from 'classnames';
import { InitContext } from '~source/ui/context';
import {
    LanguageSwitch,
    LanguageSwitchMenu,
    Link,
    MenuItems,
    SocialMediaButtons,
    WebshopButton,
} from '~source/ui/components';
import useLabel from '~source/ui/hooks/use-label';
import Analytics, { tracking } from '~source/core/services/analytics';
import $ from './hamburger-menu.scss';

interface Props {
    expanded: boolean;
    toggleExpanded: (bool?: boolean) => void;
}

const HamburgerMenu: React.FunctionComponent<Props> = ({
    expanded,
    toggleExpanded,
}: Props) => {
    const init = React.useContext(InitContext);
    const labelHome = useLabel('Home');
    const [
        languageSwitchMenuVisible,
        setLanguageSwitchMenuVisible,
    ] = React.useState(false);

    const toggleHandler = React.useCallback(() => {
        setLanguageSwitchMenuVisible(!languageSwitchMenuVisible);
    }, [languageSwitchMenuVisible]);

    const handleClick = React.useCallback(
        (bool?: boolean) => {
            toggleExpanded(bool);

            if (!languageSwitchMenuVisible) return;
            setLanguageSwitchMenuVisible(false);
        },
        [toggleExpanded, languageSwitchMenuVisible],
    );

    const clickHandler = React.useCallback(
        i => {
            Analytics.event(tracking.events.menu, 'events', {
                category: tracking.categories.menu,
                action: i.title,
                label: '',
            }),
                handleClick(false);
        },
        [handleClick],
    );

    if (!init) return null;
    const { headerMenu, footerSecondaryMenu, whiteLabel } = init;

    // TODO: move data transform to domain layer
    const homeLink = {
        englishTitle: 'home',
        title: labelHome,
        url: '/',
    };

    const headerMenuMobile =
        headerMenu && headerMenu.length
            ? [homeLink, ...headerMenu]
            : [homeLink];

    return (
        <div
            className={classnames($.container, expanded && $.containerVisible)}
        >
            <div className={$.stretch} role="button">
                <div
                    className={$.backdrop}
                    onClick={() => handleClick(false)}
                    onKeyPress={() => handleClick(false)}
                />
                <div className={$.content}>
                    <div className={$.topButtons}>
                        <Link
                            className={$.logoContainer}
                            to="/"
                            onClick={handleClick}
                        >
                            <img
                                className={$.logo}
                                src={whiteLabel.logoSmall.url}
                                alt={whiteLabel.logoSmall.title}
                            />
                        </Link>
                        <LanguageSwitch togglePopOver={toggleHandler} />
                    </div>
                    <div
                        onClick={() => handleClick}
                        role="button"
                        className={$.scroll}
                        onKeyPress={() => handleClick}
                    >
                        <MenuItems
                            className={$.headerMenu}
                            items={headerMenuMobile}
                            onClick={clickHandler}
                            vertical
                        />
                        <MenuItems
                            className={$.secondaryMenu}
                            items={footerSecondaryMenu}
                            onClick={clickHandler}
                            vertical
                        />

                        <div className={$.social}>
                            <SocialMediaButtons location="header" noTitle />
                        </div>
                    </div>

                    {init && init.shopUrl && (
                        <div className={$.webshopButton}>
                            <WebshopButton />
                        </div>
                    )}

                    <LanguageSwitchMenu visible={languageSwitchMenuVisible} />
                </div>
            </div>
        </div>
    );
};

export default HamburgerMenu;
