import {
    transformArticleGrid,
    transformContact,
    transformFeatured,
    transformForm,
    transformHtml,
    transformImage,
    transformImagePin,
    transformLocator,
    transformInspirationPackage,
    transformServices,
    transformSplit,
    transformText,
    transformTextImage,
    transformTextImageCta,
    transformUspList,
    transformVacancies,
    transformVideo,
    transformTestimonial,
} from '~source/core/transforms';
import moduleTypes from 'source/core/constants/moduleTypes';

const transformModules = (modules: any) => {
    return modules.reduce((result: any, module: any) => {
        switch (module.type) {
            case moduleTypes.featured:
                result.push(transformFeatured(module));
                break;
            case moduleTypes.image:
                result.push(transformImage(module));
                break;
            case moduleTypes.html:
                result.push(transformHtml(module));
                break;
            case moduleTypes.text:
                result.push(transformText(module));
                break;
            case moduleTypes['text-image']:
                result.push(transformTextImage(module));
                break;
            case moduleTypes['text-image-cta']:
                result.push(transformTextImageCta(module));
                break;
            case moduleTypes.video:
                result.push(transformVideo(module));
                break;
            case moduleTypes.services:
                result.push(transformServices(module));
                break;
            case moduleTypes.uspList:
                result.push(transformUspList(module));
                break;
            case moduleTypes.articleGrid:
                result.push(transformArticleGrid(module));
                break;
            case moduleTypes.form:
                result.push(transformForm(module));
                break;
            case moduleTypes.contact:
                result.push(transformContact(module));
                break;
            case moduleTypes.vacancies:
                result.push(transformVacancies(module));
                break;
            case moduleTypes.split:
                result.push(transformSplit(module));
                break;
            case moduleTypes.testimonial:
                result.push(transformTestimonial(module));
                break;
            case moduleTypes.imagePin:
                result.push(transformImagePin(module));
                break;
            case moduleTypes.inspirationPackage:
                result.push(transformInspirationPackage(module));
                break;
            case moduleTypes.locator:
                result.push(transformLocator(module));
                break;
            default:
                // eslint-disable-next-line no-console
                console.debug('Module not defined. Type: ', module.type);
                break;
        }
        return result;
    }, []);
};

export default transformModules;
