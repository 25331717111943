import * as React from 'react';
import Title from '~source/ui/components/title';
import Button from '~source/ui/components/button';
import ModuleWrapper from '~source/ui/components/modules/module-wrapper';
import HtmlText from '~source/ui/components/html-text';
import $ from './Error.scss';

const Error: React.FunctionComponent = () => {
    const text = `<p>
                    Currently we are down for maintanance and we are doing our
                    best to get every running again. Please come back later.
                </p>`;
    return (
        <ModuleWrapper className={$.error} theme="slim">
            <div className={$.wrap}>
                <Title
                    title="Sorry, we’re down for maintanance"
                    subtitle="Error 500"
                    className={$.title}
                />
                <HtmlText content={text} />
                <Button to="/" className={$.button}>
                    Try again
                </Button>
            </div>
        </ModuleWrapper>
    );
};

export default Error;
