import * as React from 'react';
import { LanguageSwitch, SocialMediaButtons } from '~source/ui/components';
import $ from './header-top-menu.scss';
import LanguageSwitchMenu from '../language-switch-menu';
import useOutsideClick from '~source/ui/hooks/use-outside-click';

interface Props {
    headerIsSmall?: boolean;
}

const HeaderTopMenu: React.FunctionComponent<Props> = (props: Props) => {
    const { headerIsSmall } = props;
    const [
        languageSwitchMenuVisible,
        setLanguageSwitchMenuVisible,
    ] = React.useState(false);

    const switchRef = React.useRef(null);
    useOutsideClick(switchRef, () => setLanguageSwitchMenuVisible(false));

    const toggleHandler = () =>
        setLanguageSwitchMenuVisible(!languageSwitchMenuVisible);

    React.useEffect(() => {
        if (headerIsSmall) setLanguageSwitchMenuVisible(false);
    }, [headerIsSmall]);

    return (
        <div className={$.headerTopMenu}>
            <div className={$.container}>
                <SocialMediaButtons location="header" />
                <div ref={switchRef}>
                    <LanguageSwitch togglePopOver={toggleHandler} />
                    <LanguageSwitchMenu visible={languageSwitchMenuVisible} />
                </div>
            </div>
        </div>
    );
};

export default HeaderTopMenu;
