import React from 'react';
import classnames from 'classnames';
import { Link } from '~source/ui/components';
import $ from './button.scss';

interface Props {
    children?: React.ReactNode;
    className?: string;
    color?: 'red' | 'blue' | 'white' | 'inverted' | 'white-inverted';
    disabled?: boolean;
    icon?:
        | 'chevron-right-white'
        | 'chevron-down-white'
        | 'chevron-left-blue'
        | 'profile';
    iconFirst?: boolean;
    onClick?: () => void;
    openInNewTab?: boolean;
    style?: React.CSSProperties;
    textLight?: boolean;
    to?: string;
    type?: 'button' | 'submit';
}

const Button: React.FunctionComponent<Props> = (props: Props) => {
    const {
        children,
        className,
        color,
        disabled,
        icon,
        iconFirst,
        onClick,
        openInNewTab,
        textLight,
        to,
        type,
        ...rest
    } = props;
    const buttonStyle = classnames(
        $.button,
        disabled && $.buttonDisabled,
        icon && $.buttonHasIcon,
        children && !iconFirst && $.buttonHasLabel,
        color === 'red' && $.buttonRed,
        color === 'white' && $.buttonWhite,
        color === 'inverted' && $.buttonInverted,
        color === 'white-inverted' && $.buttonWhiteInverted,
        textLight && $.textLight,
        className,
    );
    const buttonType = type || 'button';
    const iconSrc = `/static/icons/${icon}.svg`;
    const image = (
        <img
            src={iconSrc}
            alt="icon"
            className={classnames($.icon, iconFirst && $.iconLeft)}
        />
    );

    const content = (
        <>
            {icon && iconFirst && image}
            {children && <span className={$.label}>{children}</span>}
            {icon && !iconFirst && image}
        </>
    );

    if (to) {
        return (
            <Link
                className={buttonStyle}
                to={to}
                target={openInNewTab ? '_blank' : '_self'}
                onClick={onClick}
            >
                {content}
            </Link>
        );
    }

    return (
        // eslint-disable-next-line react/button-has-type
        <button
            className={buttonStyle}
            type={buttonType}
            disabled={disabled}
            onClick={onClick}
            {...rest}
        >
            {content}
        </button>
    );
};

export default Button;
