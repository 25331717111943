import * as React from 'react';
import { Contact as ContactType } from '~source/core/models';
import {
    ModuleWrapper,
    OpeningHours,
    ContactInfo,
    MapContact,
} from '~source/ui/components';
import $ from './contact.scss';

const Contact: React.FunctionComponent<ContactType> = (props: ContactType) => {
    const { openingHours, contactInfo, map } = props;

    return (
        <ModuleWrapper>
            <section className={$.contactModule}>
                {contactInfo && (
                    <ContactInfo
                        className={$.contactModuleItem}
                        {...contactInfo}
                    />
                )}
                {openingHours && (
                    <OpeningHours
                        className={$.contactModuleItem}
                        dates={openingHours}
                    />
                )}
                {map && <MapContact className={$.contactModuleItem} {...map} />}
            </section>
        </ModuleWrapper>
    );
};

export default Contact;
