import { UspList, Usp } from '../models';
import { isString } from '~source/utils/check-type';
import transformUsp from './transformUsp';

type Entity = {
    id: string;
    showAsUsp: true;
    title: string;
    items: Usp[];
};

export default function transformUspList(data: Entity): UspList {
    if (!data) throw new Error('Services module: no data');

    const id = data.id && isString(data.id);
    const title = data.title && isString(data.title);

    return {
        type: 'uspList',
        id,
        title,
        items: data.items.map(item => transformUsp(item)),
    };
}
