import React from 'react';
import { NavLink } from 'react-router-dom';
import { MenuItem as MenuItemModel } from '~source/core/models';
import isExternalUrl from '~source/utils/is-external-url';
import $ from './menu-item.scss';

const MenuItem = ({
    englishTitle,
    isActive,
    onClick,
    openInNewTab,
    title,
    url,
}: MenuItemModel) => {
    const target = openInNewTab ? '_blank' : '_self';
    return (
        <li key={title} className={$.listItem}>
            {isExternalUrl(url) ? (
                <a className={$.link} href={url} target={target}>
                    {title}
                </a>
            ) : (
                <NavLink
                    activeClassName={$.linkActive}
                    className={$.link}
                    exact
                    isActive={(_, { pathname }) => isActive || pathname === url}
                    onClick={() => onClick({ title: englishTitle })}
                    target={target}
                    to={url}
                >
                    {title}
                </NavLink>
            )}
        </li>
    );
};

export default MenuItem;
