import React from 'react';
import classnames from 'classnames';
import { InitContext } from '~source/ui/context';
import styles from './spinner.scss';

type Props = {
    className?: string;
};

const Spinner: React.FunctionComponent<Props> = ({ className }) => {
    const init = React.useContext(InitContext);

    return (
        <img
            data-testid="spinner"
            src={
                init && init.whiteLabel && init.whiteLabel.id === 'fleurametz'
                    ? '/static/images/spinner-fm.svg'
                    : '/static/images/spinner-default.svg'
            }
            alt="Loading..."
            className={classnames(styles.spinner, className)}
        />
    );
};

export default Spinner;
