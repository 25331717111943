import React from 'react';
import { hot } from 'react-hot-loader';
import { Switch, Route } from 'react-router-dom';
import {
    CookieAccept,
    Footer,
    Header,
    Notification,
    Spinner,
} from '~source/ui/components';
import useInitService from './hooks/use-init-service';
import { ConfigContext, InitContext } from '~source/ui/context';
import PageView from './views/page';
import { routes, paths } from './routes';
import ErrorView from './views/error';
import useColors from './hooks/use-colors';
import useFonts from './hooks/use-fonts';
import './styles/global.scss';

const App: React.FunctionComponent = () => {
    const config = window.APP_SETTINGS;

    // get user language
    const [language, setLanguage] = React.useState<string>('');

    // add user language and setter to config
    config.cultureId = language;
    config.setCultureId = setLanguage;

    // fetch init data
    const { pending, initData, isRedirect } = useInitService(config);

    useColors(initData);
    useFonts(initData);

    React.useEffect(() => {
        // update the url without reloading the app
        if (initData && initData.url) {
            window.history.replaceState(null, '', initData.url);
        }

        // get initial culture Id for tracking purposes
        if (initData && initData.cultures) {
            const activeCulture = initData.cultures.find(
                el => el.isActive === true,
            );
            if (activeCulture) {
                config.initialCultureId = activeCulture.id;
            }
        }
    }, [config.initialCultureId, initData]);

    if (pending || isRedirect) {
        return <Spinner />;
    }

    if (!initData) {
        return <ErrorView />;
    }

    return (
        <ConfigContext.Provider value={config}>
            <InitContext.Provider value={initData}>
                <>
                    <Header />
                    <Notification />
                    <Switch>
                        {routes.map(({ path }) => (
                            <Route
                                key={path}
                                path={path}
                                component={useProps => {
                                    if (path === paths.error) {
                                        return <ErrorView />;
                                    }
                                    return <PageView {...useProps} />;
                                }}
                            />
                        ))}
                    </Switch>
                    <Footer />
                    <CookieAccept />
                </>
            </InitContext.Provider>
        </ConfigContext.Provider>
    );
};

export default hot(module)(App);
