import * as React from 'react';
import { Icon, Link } from '~source/ui/components';
import { InitContext } from '~source/ui/context';
import useLabel from '~source/ui/hooks/use-label';
import $ from './social-media-buttons.scss';
import Analytics, { tracking } from '~source/core/services/analytics';

const SocialMediaStyles = {
    facebook: $.facebookIcon,
    instagram: $.instagramIcon,
    twitter: $.twitterIcon,
    youtube: $.youtubeIcon,
    pinterest: $.pinterestIcon,
    linkedin: $.linkedinIcon,
};

type Props = {
    noTitle?: boolean;
    location?: 'header' | 'footer' | 'page';
};

const SocialMediaButtons: React.FunctionComponent<Props> = (props: Props) => {
    const init = React.useContext(InitContext);
    const followUs = useLabel('FollowUs');
    const { noTitle, location } = props;
    const clickHandler = React.useCallback(
        i => {
            Analytics.event(tracking.events.social, 'events', {
                category: tracking.categories.social,
                label: location,
                action: i.socialMediaType,
            });
        },
        [location],
    );
    if (!init) return null;

    const { socialMediaItems } = init;
    if (!socialMediaItems || socialMediaItems.length === 0) return null;

    return (
        <div className={$.socialMediaButtons}>
            {!noTitle && <span className={$.followUs}>{followUs}</span>}

            {socialMediaItems.map(item => (
                <Link
                    key={item.socialMediaType}
                    to={item.link.url}
                    onClick={() => clickHandler(item)}
                >
                    <Icon
                        size="small"
                        className={SocialMediaStyles[item.socialMediaType]}
                    />
                </Link>
            ))}
        </div>
    );
};

export default SocialMediaButtons;
