import React from 'react';
import cx from 'classnames';

import $ from './tag.scss';

interface Props {
    className?: string;
    children: React.ReactNode;
}

const Tag: React.FC<Props> = ({ className, children }) => (
    <span className={cx($.tag, className)}>{children}</span>
);

export default Tag;
