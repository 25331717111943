import React from 'react';
import classnames from 'classnames';
import sanitizeHtml from 'sanitize-html';
import $ from './html-text.scss';

type Props = {
    content?: string;
    className?: string;
    noStyling?: boolean;
    allowTags?: string[];
};
const { defaults } = sanitizeHtml;
const HtmlText: React.FunctionComponent<Props> = ({
    content,
    className,
    noStyling,
    allowTags,
}) => {
    const tags = allowTags || ['img', 'h2'];
    const sanitizedContent = React.useMemo(() => {
        return sanitizeHtml(content, {
            allowedTags: [...defaults.allowedTags, ...tags],
            allowedAttributes: false, // allow all attributes
            allowedSchemes: [...defaults.allowedSchemes, 'tel'],
        });
    }, [content, tags]);
    return (
        <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: sanitizedContent }}
            className={classnames(!noStyling && $.container, className)}
        />
    );
};
export default HtmlText;
