import { Text as TextModel } from '../models';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Entity = any;

export default function transformText(data: Entity): TextModel {
    if (!data) throw new Error('Text module: no data');

    const { id, title, topTitle, body, type } = data;

    return {
        type: 'text',
        id,
        body,
        topTitle,
        title,
    };
}
