import { transformLink } from '~source/core/transforms';
import { Locator } from '../models/module-locator';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Entity = any;

function transformImage(data: Entity) {
    return {
        id: data.id,
        title: data.title,
        url: data.url,
    };
}

function transformMarker(mapType: string, data: Entity) {
    if (mapType === 'buildings') {
        return {
            address: data.address,
            coordinates: {
                lat: data.latitude,
                lng: data.longitude,
            },
            icon: data.icon,
            id: data.id,
            link: data.link && transformLink(data.link),
            title: data.title,
            topTitle: data.topTitle,
            type: 'building',
        };
    }
    if (mapType === 'preferredSuppliers') {
        return {
            coordinates: {
                lat: data.latitude,
                lng: data.longitude,
            },
            icon: data.icon,
            id: data.id,
            image: data.image,
            link: data.link && transformLink(data.link),
            title: data.title,
            topTitle: data.topTitle,
            type: 'preferredSupplier',
        };
    }
    if (mapType === 'contactPersons') {
        return {
            coordinates: {
                lat: data.latitude,
                lng: data.longitude,
            },
            countries: data.countries,
            email: data.email,
            icon: transformImage(data.icon),
            id: data.id,
            image: data.image && transformImage(data.image),
            phone: data.phoneNumber,
            title: data.title,
            topTitle: data.topTitle,
            type: 'contactPerson',
        };
    }
}

export default function transformLocator(data: Entity): Locator {
    if (
        data.worldMapType !== 'buildings' &&
        data.worldMapType !== 'contactPersons' &&
        data.worldMapType !== 'preferredSuppliers'
    ) {
        throw new Error(`Locator: invalid map type ${data.worldMapType}.`);
    }

    return {
        highlightedCountries: data.highlightedCountries,
        id: data.id,
        legend: {
            items: data.legend.items && data.legend.items.map(transformImage),
            link: data.legend.link && transformLink(data.legend.link),
            title: data.legend.title,
            topTitle: data.legend.topTitle,
        },
        mapType: data.worldMapType,
        markers: data.pins
            .filter(pin => pin.latitude && pin.longitude)
            .map(pin => {
                return transformMarker(data.worldMapType, pin);
            }),
        tentative: !!data.tentative,
        type: 'locator',
    };
}
