import React from 'react';
import classnames from 'classnames';
import $ from './submenu.scss';

const Submenu = ({ children, isSmall, setShowSubmenu, visible }) => (
    <div
        className={classnames(
            $.submenu,
            visible && $.submenuOpen,
            isSmall && $.small,
        )}
        onMouseEnter={() => setShowSubmenu(true)}
        onMouseLeave={() => setShowSubmenu(false)}
    >
        <div className={$.submenuChildWrapper}>{children}</div>
    </div>
);

export default Submenu;
