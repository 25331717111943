import { Split } from '~source/core/models';
import { isString } from '~source/utils/check-type';
import { transformModules } from '~source/core/transforms';

type Entity = any;

export default function transformSplit(data: Entity): Split {
    if (!data) throw new Error('Split: no data');

    const id = data.id && isString(data.id);
    const modulesLeft = transformModules(data.modulesLeft);
    const modulesRight = transformModules(data.modulesRight);

    return {
        id,
        type: 'split',
        modulesLeft,
        modulesRight,
    };
}
