import { Link } from 'source/core/models';

export class Pin {
    deepLink: Link;
    englishTitle: string;
    id: string;
    imageUrl: string;
    isMultiVariantDeepLink: boolean;
    moduleTitle: string;
    packageId: string;
    posX: number;
    posY: number;
    subsetId: string;
    title: string;

    constructor(data: any) {
        this.deepLink = data.deepLink;
        this.englishTitle = data.englishTitle;
        this.id = data.id;
        this.imageUrl = data.imageUrl;
        this.isMultiVariantDeepLink = data.isMultiVariantDeepLink;
        this.moduleTitle = data.moduleTitle;
        this.packageId = data.packageId;
        this.posX = data.posX;
        this.posY = data.posY;
        this.subsetId = data.subsetId;
        this.title = data.title;
    }
}

export default Pin;
