import React from 'react';

import { Button } from '~source/ui/components';
import { Link } from '~source/core/models';

import $ from './popup-locator.scss';

type Props = {
    address: string;
    link?: Link;
    title: string;
    topTitle: string;
};

const PopupLocatorLocation: React.FC<Props> = ({
    address,
    link,
    title,
    topTitle,
}) => (
    <div className={$.location}>
        <span className={$.topTitle}>{topTitle}</span>
        <span className={$.title}>{title}</span>
        <span className={$.address}>{address}</span>
        {link && (
            <Button
                className={$.button}
                color="blue"
                icon="chevron-right-white"
                openInNewTab={link.openInNewTab}
                to={link.url}
            >
                {link.title}
            </Button>
        )}
    </div>
);

export default PopupLocatorLocation;
