import React from 'react';

import { InspirationPackage, Subset } from '~source/core/models';
import { CardInspiration, Grid } from '..';
import { GridSize, CellSize } from '../grid/grid';
import $ from './inspiration-grid.scss';
import breakpoints from '~source/core/constants/breakpoints';
import { useLabel, useWindowWidth } from '~source/ui/hooks';
import Analytics, { tracking } from '~source/core/services/analytics';

function getImageSize(cellSize: CellSize, isMobile: boolean) {
    if (isMobile) {
        return 320;
    }
    switch (cellSize) {
        case 'small':
            return 450;
        case 'medium':
            return 750;
        case 'large':
            return 960;
        case 'large-wide':
        default:
            return 1400;
    }
}

const InspirationGrid: React.FC<InspirationPackage> = ({
    subsets,
    title,
    id,
}) => {
    const labels = {
        package: useLabel('Inspiration/DiscoverPackage'),
        webshop: useLabel('General/Webshop'),
    };
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < breakpoints.large;

    function onClickHandler(
        subset: Subset,
        type: 'cta' | 'webshop',
        position: number,
        totalPositions: number,
    ) {
        switch (type) {
            default:
            case 'cta': {
                Analytics.event(tracking.events.cta, 'events', {
                    category: tracking.categories.inspiration,
                    action: `${
                        subset.lookbookLink.englishTitle
                    } | ${position} out of ${totalPositions}`,
                    label: `${subset.title} | ${subset.topTitle} | ${
                        subset.id
                    }`,
                    grid: position,
                });
            }
            case 'webshop': {
                Analytics.event(tracking.events.redirect, 'events', {
                    category: tracking.categories.inspirationRedirect,
                    action: `${
                        subset.productsLink.englishTitle
                    } | ${position} out of ${totalPositions}`,
                    label: `${subset.title} | ${subset.topTitle} | ${
                        subset.id
                    }`,
                    grid: position,
                });
            }
        }
    }

    function renderCell(index: number, cellSize: CellSize) {
        const subset = subsets[index];
        return (
            <CardInspiration
                heightPercentageOfWidth={cellSize === 'large-wide' ? 50 : 72}
                imageSrc={subset.image.url}
                imageWidth={getImageSize(cellSize, isMobile)}
                linkPrimary={{ ...subset.lookbookLink, title: labels.package }}
                linkSecondary={{
                    ...subset.productsLink,
                    title: labels.webshop,
                    openInNewTab: true,
                }}
                size={cellSize === 'large-wide' ? 'large' : cellSize}
                title={subset.title}
                topTitle={subset.topTitle}
                onPrimaryClick={() =>
                    onClickHandler(subset, 'cta', index + 1, subsets.length)
                }
                onSecondaryClick={() =>
                    onClickHandler(subset, 'webshop', index + 1, subsets.length)
                }
            />
        );
    }
    return (
        <section className={$.inspirationGrid}>
            <h2 className={$.title}>{title}</h2>
            <Grid size={subsets.length as GridSize} renderCell={renderCell} />
        </section>
    );
};

export default InspirationGrid;
