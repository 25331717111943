import React from 'react';
import { Services as ServicesModel } from '~source/core/models';
import { ModuleWrapper, Title, ServiceItem } from '~source/ui/components';
import $ from './services-default.scss';

const ServicesDefault: React.FunctionComponent<ServicesModel> = ({
    body,
    items,
    label,
    title,
    topTitle,
}: ServicesModel) => (
    <section className={$.background}>
        <ModuleWrapper className={$.container}>
            <section className={$.wrap}>
                <div className={$.top}>
                    <Title
                        title={topTitle}
                        subtitle={title}
                        className={$.topTitle}
                        size="large"
                    />
                    {body && <p className={$.topBody}>{body}</p>}
                </div>
                <div className={$.label}>{label}</div>
                <div className={$.items}>
                    {items &&
                        items.map(item => (
                            <ServiceItem
                                {...item}
                                layout="default"
                                key={item.title}
                            />
                        ))}
                </div>
            </section>
        </ModuleWrapper>
    </section>
);

export default ServicesDefault;
