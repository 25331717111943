import * as React from 'react';
import classnames from 'classnames';
import { Vacancies as VacanciesType } from '~source/core/models';
import { Title, ModuleWrapper, CardHorizontal } from '~source/ui/components';
import $ from './vacancies.scss';

interface Props extends VacanciesType {
    className?: string;
}

const Vacancies: React.FunctionComponent<Props> = (props: Props) => {
    const { className, title, items } = props;

    return (
        <ModuleWrapper className={classnames($.vacancies, className)}>
            <>
                <Title subtitle={title} />
                {items &&
                    items.length > 0 &&
                    items.map(item => (
                        <CardHorizontal {...item} key={item.title} />
                    ))}
            </>
        </ModuleWrapper>
    );
};

export default Vacancies;
