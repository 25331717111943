import React, { lazy, Suspense } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Locator as LocatorModel } from '~source/core/models';
import isIE from '~source/utils/is-ie';
import Spinner from '~source/ui/components/spinner';

// Lazy load locator because IE isn't supported
const Locator = !isIE() ? lazy(() => import('./locator')) : null;

type Props = RouteComponentProps & LocatorModel;

const LocatorContainer: React.FC<Props> = props => {
    return (
        <Suspense fallback={<Spinner />}>
            {Locator && <Locator {...props} />}
        </Suspense>
    );
};

export default withRouter(LocatorContainer);
