import * as React from 'react';
import { Icon } from '~source/ui/components';
import useLabel from '~source/ui/hooks/use-label';
import $ from './copyright-notice.scss';

const CopyrightNotice: React.FunctionComponent = () => {
    const allRightsReserved = useLabel('AllRightsReserved');
    const company = useLabel('Fleurametz');
    const year = new Date().getFullYear();
    const text = `${company} ${year}. ${allRightsReserved}.`;

    return (
        <div className={$.copyrightNotice}>
            <p>{text}</p>
        </div>
    );
};

export default CopyrightNotice;
