export const tracking = {
    events: {
        accountRequest: 'interactionAccountRequest',
        contactForm: 'interactionContactForm',
        cookie: 'interactionCookieConsent',
        cta: 'interactionCTA',
        footerMenu: 'interactionFooterMenu',
        menu: 'interactionMenu',
        pageMenu: 'interactionMenuOnPage',
        pageView: 'virtualPageView',
        pinClick: 'interactionPinClick',
        pinView: 'interactionPinView',
        promoView: 'eecPromoView',
        redirect: 'interactionRedirect',
        social: 'interactionSocialMedia',
    },
    categories: {
        account: 'account',
        accountRequest: 'account request',
        contact: 'contact',
        cta: 'CTA',
        ecommerce: 'ecommerce',
        flowerTales: 'Flowertales',
        footer: 'footer',
        inspiration: 'Flowertales CTA',
        inspirationPinClick: 'Flowertales pin click',
        inspirationPinView: 'Flowertales pin view',
        inspirationRedirect: 'Flowertales redirect',
        menu: 'menu',
        social: 'social media',
    },
};

const Analytics = {
    event(
        event: string,
        key: string = 'events',
        options:
            | {
                  category: string;
                  action: string;
                  label?: string;
                  grid?: number;
              }
            | { basic: boolean; statistics: boolean; marketing: boolean }
            | {
                  name?: string; // Unique page name
                  url: string; // the slug of the page
                  countryCode?: string; // the selected country
                  searchTerm?: string; // posted search term
                  searchResults?: string; // amount of search results
              }
            | {
                  category: string;
                  action: string;
                  genericItem: string;
                  name: string;
                  creative: string;
                  position: number;
              },
        user?: {
            clientId: string;
            sessionId: string;
            trafficType: string;
        },
    ) {
        const eventData = {
            event,
            [key]: options,
            user,
        };
        // TAG MANAGER
        Analytics.push(eventData);
    },

    push(obj) {
        if (window.dataLayer) {
            window.dataLayer.push(obj);
        }
    },
};

export default Analytics;
