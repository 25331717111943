import * as React from 'react';
import { ImageModel } from '~source/core/models';
import { ModuleWrapper } from '~source/ui/components';
import $ from './image-module.scss';
import Image from '../../image';
import breakpoints from '~source/core/constants/breakpoints';

const ImageModule: React.FunctionComponent<ImageModel> = (
    props: ImageModel,
) => {
    const { images } = props;
    if (!images) return null;
    const imageWidth = window.innerWidth <= breakpoints.medium ? 700 : 1410;
    return (
        <ModuleWrapper className={$.container}>
            <div className={$.images}>
                {images.map(i => (
                    <div className={$.imageContainer} key={i.url}>
                        <Image
                            placeholder={`${i.url}?w=30&q=50`}
                            src={`${i.url}?w=${imageWidth}&q=75`}
                            alt={i.title}
                            className={$.image}
                        />
                    </div>
                ))}
            </div>
        </ModuleWrapper>
    );
};

export default ImageModule;
