interface Response {
    type: string;
    url?: string;
}

export default function checkResponse(
    acceptedTypes: string[] = [],
    response: Response,
) {
    if (
        typeof response !== 'object' ||
        Array.isArray(response) ||
        !response.type ||
        typeof response.type !== 'string' ||
        !acceptedTypes.includes(response.type)
    ) {
        // Fire redirect if response is meant to redirect the user
        if (
            response.type === 'redirectResponse' &&
            typeof response.url === 'string'
        ) {
            window.location.replace(response.url);
        }
        return null;
    }

    return response;
}
