import { Featured } from '../models';
import { isString, isBool } from '~source/utils/check-type';
import transformCard from './transformCard';
import transformImagePin from './transformImagePin';
import { moduleLabels } from '../constants/moduleTypes';

export default function transformFeatured(data: any): Featured {
    if (!data) throw new Error('Featured module: no data');

    const id = data.id && isString(data.id);
    const items = data.items.map(item => {
        if (item.type === 'a60') {
            return transformImagePin(item);
        }

        return transformCard(item);
    });
    const largeOnTop = (data.largeOnTop && isBool(data.largeOnTop)) || false;
    const subtitle = data.topTitle && isString(data.topTitle);
    const title = data.title && isString(data.title);
    const type = moduleLabels.featured;

    return {
        id,
        items,
        largeOnTop,
        subtitle,
        title,
        type,
    };
}
