import { Service } from '../models';
import { isString } from '~source/utils/check-type';
import { transformLink } from '~source/core/transforms';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Entity = any;

export default function transformService(data: Entity): Service {
    if (!data) throw new Error('Service: no data');

    const id = data.id && isString(data.id);
    const title = data.title && isString(data.title);
    const body = data.body && isString(data.body);
    const image = {
        title:
            data.iconImage &&
            data.iconImage.title &&
            isString(data.iconImage.title),
        url:
            data.iconImage &&
            data.iconImage.url &&
            isString(data.iconImage.url),
    };

    const link = data.link && transformLink(data.link);

    return {
        id,
        title,
        body,
        image,
        link,
    };
}
