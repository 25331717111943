import * as React from 'react';
import classnames from 'classnames';
import { ArticleTeaser as ArticleTeaserType } from '~source/core/models';
import Image from '~source/ui/components/image';
import ArticleTag from '~source/ui/components/article-tag';
import Link from '~source/ui/components/link';
import $ from './article-teaser.scss';

interface Props {
    layout: 'card' | 'list-item';
    size?: 'small' | 'large';
    className?: string;
}

type CombinedProps = Props & ArticleTeaserType;

const ArticleTeaser: React.FunctionComponent<CombinedProps> = props => {
    const {
        layout,
        className,
        size,
        title,
        intro,
        date,
        thumbnailImage,
        mainTag,
        url,
    } = props;

    const wrapClass = classnames([
        $.article,
        size === 'large' && $.articleLarge,
        layout === 'list-item' && $.articleListItem,
        className && className,
    ]);

    const imageListItem = 300;
    const imageCardSmall = 460;
    const imageCardLarge = 950;
    const imageWidthCard = size === 'large' ? imageCardLarge : imageCardSmall;
    const imageWidth = layout === 'list-item' ? imageListItem : imageWidthCard;

    return (
        <article className={wrapClass}>
            <header className={$.articleHeader}>
                <Image
                    placeholder={`${thumbnailImage.url}?w=30&q=50`}
                    src={`${thumbnailImage.url}?w=${imageWidth}&q=75`}
                    alt={thumbnailImage.title}
                    className={$.articleHeaderImage}
                    isBackground
                />
            </header>
            <div className={$.articleContent}>
                <ArticleTag mainTag={mainTag} />
                <Link to={url} className={$.articleContentLink} title={title}>
                    <h3 className={$.articleContentTitle}>{title}</h3>
                </Link>
                {date && <p className={$.articleContentDate}>{date}</p>}
                {intro && <p className={$.articleContentIntro}>{intro}</p>}
            </div>
        </article>
    );
};

export default ArticleTeaser;
